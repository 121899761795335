import { Icon } from '@iconify/react'
import parse from 'html-react-parser'
import { marked } from 'marked'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatInput } from 'src/components/inputs'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import CAIIconThinking from '../../CaiIcon/CAIIconThinking'
import FileReference from './FileReference'

function Librarian(props) {
  const { t } = useTranslation()
  const { messages, awaitingResponse, ...rest } = props
  const { librarianMsg } = useContext(CaiPopupContext)

  return (
    <div
      className="d-flex card card-chat-body border-0  w-100 h-100 overflow-hidden"
      style={{
        backgroundColor: 'var(--librarian-color)',
        paddingBottom: '0 !important',
      }}
    >
      <div
        className="d-flex position-relative"
        style={{
          overflow: 'hidden',
          height: `80%`,
          maxHeight: `80%`,
        }}
      >
        <div
          className="flex-grow-1 rounded-3 w-100"
          style={{ backgroundColor: '#EAF2FF' }}
        >
          <div className="d-flex p-3 h-100 align-items-start flex-column">
            <div className="h-100 w-100 d-flex flex-column">
              <div className="flex-grow-1 mh-100 overflow-auto gap-3 d-flex flex-column">
                <div
                  className="form-control flex-grow-1 h-50 overflow-y-auto rounded-2"
                  style={{ backgroundColor: '#ffffff' }}
                >
                  {messages.length ? (
                    messages[messages.length - 1].owner !== 'user' && (
                      <>
                        {parse(
                          marked.parse(messages[messages.length - 1].message),
                        )}
                      </>
                    )
                  ) : librarianMsg ? (
                    <div>{librarianMsg}</div>
                  ) : (
                    <div>
                      {t(
                        'caiPopup.librarian.awaitingQuery',
                        'Awaiting query...',
                      )}
                    </div>
                  )}

                  {awaitingResponse && (
                    <CAIIconThinking awaitingResponse={awaitingResponse} />
                  )}
                </div>
              </div>

              <ChatInput
                {...rest}
                awaitingResponse={awaitingResponse}
                retainInput={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column"
        style={{
          height: `20%`,
          maxHeight: `20%`,
          marginBottom: '5px',
          minHeight: '150px',
          padding: '0 1rem 1rem 1rem',
        }}
      >
        <div className="d-flex py-2">
          <Icon
            height="24"
            icon="material-symbols-light:book-4-spark-outline-rounded"
            width="24"
          />
          <h5
            style={{
              margin: 0,
            }}
          >
            {t('common.word.references')}
          </h5>
        </div>
        <div
          className="card flex-grow-1"
          style={{
            margin: 0,
          }}
        >
          <div
            className="card-body "
            style={{
              height: '100%',
              overflow: 'auto',
              backgroundColor: '#ffffff',
            }}
          >
            {[
              ...new Map(
                messages[messages.length - 1]?.references?.map((reference) => [
                  reference.name,
                  reference,
                ]),
              ).values(),
            ].map((reference) => (
              <FileReference
                key={reference.uri + reference.name}
                link={reference.uri}
                text={reference.name}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Librarian)
