import { ConfigProvider, Grid } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import {
  CSSProperties,
  FC,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
} from 'react'

const { useBreakpoint } = Grid
interface TextInputProps {
  labelPosition?: 'left' | 'right' | 'top' | 'bottom'
  children?: ReactNode
  isRequired?: boolean
  noTranslate?: boolean
  prefix?: ReactNode
  suffix?: ReactNode
  sxBox?: CSSProperties
  sx?: CSSProperties
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  [key: string]: unknown
}

export const TextInput: FC<TextInputProps> = forwardRef<
  HTMLDivElement,
  TextInputProps
>((props, ref) => {
  const {
    labelPosition = 'left',
    children: child,
    isRequired = false,
    noTranslate = false,
    prefix,
    suffix,
    sxBox,
    sx,
    onChange,
    ...rest
  } = props

  // Responsive handling
  const screens = useBreakpoint()
  const isSmall = !screens.md

  const _labelPosition = !isSmall && labelPosition === 'left' ? 'left' : 'top'
  return (
    <div
      style={{
        display: 'inline-flex',
        flex: 1,
        width: '100%',
        alignItems: _labelPosition === 'left' ? 'center' : 'flex-start',
        flexDirection: _labelPosition !== 'left' ? 'column' : 'row',
        position: 'relative',
        ...sxBox,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexGrow: 0,
          width: '100%', // minWidth: _labelPosition === 'left' ? '80px' : 'auto',
          border: '1px solid',
          borderRadius: '5px',
          padding: '5px 10px',
          ...sx,
        }}
      >
        {isValidElement(prefix) && cloneElement(prefix)}
        <ConfigProvider
          theme={{
            token: {
              colorBorder: 'transparent',
            },
            components: {
              Input: {
                activeShadow: 'transparent',
                hoverBorderColor: 'transparent',
                activeBorderColor: 'white',
                colorBgContainerDisabled: 'transparent',
              },
            },
          }}
        >
          <TextArea
            ref={ref}
            autoSize={{ minRows: 1, maxRows: 5 }}
            placeholder="Message"
            onChange={onChange}
            {...rest}
          />
        </ConfigProvider>

        {isValidElement(suffix) && cloneElement(suffix)}
      </div>
    </div>
  )
})
