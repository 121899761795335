import type { SelectProps } from 'antd'
import { Select, Space } from 'antd'
import { FC, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { validateNotEmpty } from './utils'

interface SelectInputProps extends SelectProps {
  required?: boolean
  source: string
  optionName?: string
  optionValue?: string
  disabled?: boolean
  validate?: (value: string[]) => string | true
  label?: string
  placeholder?: string
  showLabel?: boolean
  style?: React.CSSProperties
}

export const SelectInput: FC<SelectInputProps> = (props) => {
  const {
    source,
    options = [],
    optionName = 'label',
    optionValue = 'value',
    disabled = false,
    validate,
    mode,
    label = '',
    required = false,
    defaultValue,
    placeholder = 'Please select',
    showLabel = true,
    style,
    ...rest
  } = props
  const { control, setValue, watch, getValues } = useFormContext()
  const { t } = useTranslation()
  const selectedValues =
    watch(source) ||
    (mode === 'multiple' ? (getValues(source) ?? []) : undefined)

  useEffect(() => {
    const optionValues = options.map((option) => option[optionValue])
    if (!optionValues.length) return

    let validValues
    if (mode === 'multiple' && Array.isArray(selectedValues)) {
      validValues = selectedValues.filter((value: unknown) =>
        optionValues.includes(value),
      )
    } else {
      validValues = optionValues.includes(selectedValues)
        ? selectedValues
        : undefined
    }

    if (JSON.stringify(validValues) !== JSON.stringify(selectedValues)) {
      setValue(source, validValues)
    }
  }, [selectedValues, options])

  return (
    <Space direction="vertical" style={{ flex: 1, width: '100%', minWidth: 0 }}>
      {showLabel && (
        <label className="form-label">
          {t(label)}
          {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={source}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div>
              <Select
                {...rest}
                allowClear
                className="antd-select"
                disabled={disabled}
                mode={mode}
                optionFilterProp={optionName}
                options={options}
                placeholder={t(placeholder)}
                style={{ width: '100%', ...style }}
                value={value}
                fieldNames={{
                  label: optionName,
                  value: optionValue,
                }}
                getPopupContainer={(trigger) =>
                  trigger.parentElement || document.body
                }
                onChange={(newValue) => {
                  const finalValue =
                    newValue ?? (mode === 'multiple' ? [] : null)
                  onChange(finalValue)
                }}
              />
              {error && <div style={{ color: 'red' }}>{error.message}</div>}
            </div>
          )
        }}
        rules={{
          validate: (value) => {
            if (required) {
              return validate ? validate(value) : validateNotEmpty(value)
            }
            return true
          },
        }}
      />
    </Space>
  )
}
