export enum OrganizationCategory {
  All = 'All',
  Company = 'Company',
  NGO = 'NGO',
  Government = 'Government',
  Family = 'Family',
  Community = 'Community',
  Personal = 'Personal',
}

type OrganizationCategoryType = {
  value: keyof typeof OrganizationCategory
  label: OrganizationCategory
}

// Create an array of key-value pairs
export const OrganizationCategoryOptions: OrganizationCategoryType[] =
  Object.entries(OrganizationCategory).map(([value, label]) => ({
    value: value as keyof typeof OrganizationCategory,
    label: label as OrganizationCategory,
  }))
