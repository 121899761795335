import { omit, sample, times } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/components/buttons'
import { languageOptions } from 'src/constants/i18n.constant'
import {
  OrganizationCategory,
  OrganizationCategoryOptions,
} from 'src/types/organization.type'
import {
  GetEmployeesDocument,
  GetMeDocument,
  GetOrganizationsDocument,
  OrganizationType,
  useGetWorkingRolesQuery,
  useSaveCompanyMutation,
} from '../../gql/graphql'
import { AWSS3FolderName } from '../../utils/fileUploader'
import { AuthContext } from '../Auth/AuthProvider'
import Error from '../common/Error'
import { usePresignedUrls } from 'src/hooks/usePresignedUrls'
import { toast } from 'react-toastify'

export default function CreateCompanyForm({ btnProps }) {
  const { t } = useTranslation()
  const { uploadFileToS3 } = usePresignedUrls()
  const PASSWORD_CHARSET =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+'
  const PASSWORD_LENGTH = 16

  const { register, setValue, handleSubmit, reset, control } = useForm({
    defaultValues: {
      password: times(PASSWORD_LENGTH, () => sample(PASSWORD_CHARSET)).join(''),
    },
  })

  const { userInfo } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const organizationTypes = OrganizationCategoryOptions.filter(
    (organization) =>
      ![OrganizationCategory.All, OrganizationCategory.Personal].includes(
        organization.label,
      ),
  )

  const {
    data: roles,
    loading: rolesLoading,
    error: rolesError,
  } = useGetWorkingRolesQuery({
    skip: !open,
  })
  const [
    saveCompany,
    { loading: saveCompanyLoading, error: saveCompanyError },
  ] = useSaveCompanyMutation()

  const loading = rolesLoading
  const error = rolesError

  const toggleOpen = () => setOpen((state) => !state)
  const toggleShowPassword = () => setShowPassword((state) => !state)
  const onSubmit = async (values) => {
    try {
      const logo =
        values?.logo?.length > 0
          ? await uploadFileToS3(
              values?.logo?.[0],
              AWSS3FolderName.ORGANIZATIONS,
              values?.logo?.[0]?.name,
            )?.location
          : null

      const createOrgSTS = await fetch(
        `${process.env.REACT_APP_STS_ENDPOINT}/organisation`,
        {
          method: 'POST',
          body: JSON.stringify({
            name: values.company,
          }),
        },
      )
      const createOrgSTSJson = await createOrgSTS.json()
      let stsOrganisationId = createOrgSTSJson?.id
      // const passwordHash = await hashPassword(values.password)
      // const stsResponse = await fetch(
      //   `${process.env.REACT_APP_STS_ENDPOINT}/register-direct`,
      //   {
      //     method: 'POST',
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({
      //       email: values.email,
      //       password_hash: passwordHash,
      //       organisation_id: stsOrganisationId,
      //     }),
      //   },
      // )
      // if (!stsResponse.ok) {
      //   toast.error(t('common.toast.error'))
      //   return
      // }
      // const stsResponseJson = await stsResponse.json()

      await saveCompany({
        variables: {
          userId: userInfo?.id,
          roleId: userInfo?.employeeDetails.role.id,
          input: {
            ...omit(values, ['username', 'password']),
            logo,
            stsOrganisationId,
          },
        },
        refetchQueries: [
          GetMeDocument,
          GetEmployeesDocument,
          GetOrganizationsDocument,
          // GetTeamsDocument
        ],
      })
      toggleOpen()
      toast.success(
        t('notification.resource.create', {
          name: t('common.word.workspace'),
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!open) {
      reset()
      setShowPassword(false)
    }
  }, [open])

  return (
    <>
      <Button
        label={t('it.createCompanyForm.createCompany', 'Create New Company')}
        onClick={toggleOpen}
        {...btnProps}
      />
      <Modal centered show={open} size="lg" onHide={toggleOpen}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">
              {t('it.createCompanyForm.createCompany', 'Create New Company')}
            </Modal.Title>
          </Modal.Header>
          {loading ? (
            <div
              className="spinner-border text-primary mx-auto my-3 d-flex "
              role="status"
            >
              <span className="visually-hidden">
                {t('common.word.loading')}...
              </span>
            </div>
          ) : error ? (
            <Error />
          ) : (
            <>
              <Modal.Body>
                <div className="modal-body row row-gap-3">
                  <div className="col-12">
                    <strong>
                      {t(
                        'it.createCompanyForm.form.generalInformation',
                        'Company General Information',
                      )}
                    </strong>
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      {t('it.createCompanyForm.form.name', 'Company Name')} *
                    </label>
                    <input
                      required
                      className="form-control"
                      type="text"
                      {...register('company', { required: true })}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      {t('common.word.industryDomain', 'Industry/Domain')} *
                    </label>
                    <input
                      required
                      className="form-control"
                      type="text"
                      {...register('industry', { required: true })}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      {t('it.createCompanyForm.form.type')} *
                    </label>
                    <select
                      className="form-select"
                      {...register('category', { required: true })}
                    >
                      {organizationTypes.map((type) => (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      {t('it.createCompanyForm.form.language')}
                    </label>
                    <select
                      className="form-select"
                      {...register('defaultLanguage', { required: false })}
                    >
                      {languageOptions.map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <label className="form-label">
                      {t('it.createCompanyForm.form.logo', 'Company Logo')}
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      {...register('logo')}
                    />
                  </div>
                  <div className="col-12">
                    <strong>
                      {t(
                        'it.createCompanyForm.form.address',
                        'Company Address',
                      )}
                    </strong>
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      {t('common.word.street', 'Street')}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register('street')}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      {t('common.word.city', 'City')} *
                    </label>
                    <input
                      required
                      className="form-control"
                      type="text"
                      {...register('city', { required: true })}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      {t('common.word.country', 'Country')} *
                    </label>
                    <input
                      required
                      className="form-control"
                      type="text"
                      {...register('country', { required: true })}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      {t(
                        'it.createCompanyForm.form.parentCompany',
                        'Parent Company',
                      )}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...register('parentCompany')}
                    />
                  </div>
                  <div className="col-12">
                    <strong>
                      {t(
                        'it.createCompanyForm.form.adminProfile',
                        'Admin Profile',
                      )}
                    </strong>
                  </div>

                  <div className="col-6">
                    <label className="form-label">
                      {t('common.word.email')}
                    </label>
                    <input
                      disabled
                      required
                      className="form-control"
                      type="email"
                      value={userInfo?.email}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex align-items-center gap-3">
                  {saveCompanyError && (
                    <span className="text-danger">
                      {saveCompanyError.message}
                    </span>
                  )}
                  <button
                    className="btn btn-primary btn-lg"
                    disabled={saveCompanyLoading}
                    type="submit"
                  >
                    {t('common.button.create')}
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </form>
      </Modal>
    </>
  )
}
