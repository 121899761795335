import { Icon } from '@iconify/react'
import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import { CSSProperties, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import styled, { keyframes, css } from 'styled-components'

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`

const AnimatedIcon = styled(Icon)<{ $isCopied: boolean }>`
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1);
  }

  ${({ $isCopied }) =>
    $isCopied &&
    css`
      animation: ${bounce} 0.5s ease;
    `}
`

export const CopyButton = ({
  text,
  placement = 'left',
  style,
}: {
  text: string
  placement?: TooltipPlacement
  style?: CSSProperties
}) => {
  const [isCopied, setIsCopied] = useState(false)
  const { t } = useTranslation()

  const title = isCopied ? t('common.button.copied') : t('common.button.copy')

  const handleCopy = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <Tooltip placement={placement} title={title}>
        <button
          className="btn "
          style={{
            padding: 10,
            borderWidth: 0,
            borderStyle: 'solid',
            borderColor: isCopied ? 'transparent' : '',
            ...style,
          }}
        >
          <AnimatedIcon
            $isCopied={isCopied}
            height="20"
            icon="material-symbols-light:file-copy-outline"
            width="20"
          />
        </button>
      </Tooltip>
    </CopyToClipboard>
  )
}
