import { isMobile } from 'react-device-detect'
import CAIIcon from '../CaiIcon'
import HeaderButtons from '../CaiPopup/HeaderButtons'
import { Divider } from 'antd'
import Tooltip from '../UI/Tooltip'
import { useTranslation } from 'react-i18next'
import { useContext, useMemo, memo } from 'react'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import Notification from 'src/components/Notification'
import { Message } from 'src/types/session.type'

interface CaiChatHeaderProps {
  handleShow: () => void
  fullScreen: boolean
  handleCompleteActivity?: () => void
  flow: string
  newChat: () => void
  setMessagesMap: React.Dispatch<
    React.SetStateAction<Record<string, Message[]>>
  >
  handleFullSize?: React.MouseEventHandler<HTMLElement>
}

const HeaderLogo = ({ handleShow }: { handleShow: () => void }) => (
  <a className="m-2" href="#!" title="" onClick={handleShow}>
    <CAIIcon isCai style={{ width: 35, height: 35 }} />
  </a>
)

const HeaderTitle = ({
  fullScreen,
  t,
}: {
  fullScreen: boolean
  t: (key: string) => string
}) => {
  if (!fullScreen || isMobile) return null

  return (
    <div className="d-flex align-items-center flex-row gap-1">
      <h6 className="mb-0">{t('app.caiChat.cai')}</h6>
      <small className="text-muted" style={{ marginTop: 2 }}>
        {t('app.caiChat.description')}
      </small>
    </div>
  )
}

const HeaderControls = memo(
  ({
    fullScreen,
    handleFullSize,
    handleShow,
    flow,
    newChat,
    setMessagesMap,
    handleCompleteActivity,
  }: {
    fullScreen: boolean
    handleFullSize?: React.MouseEventHandler<HTMLElement>
    handleShow: () => void
    flow: string
    newChat: () => void
    setMessagesMap: React.Dispatch<
      React.SetStateAction<Record<string, Message[]>>
    >
    handleCompleteActivity?: () => void
  }) => {
    const { sizeBtnRef, draggingBtnRef } = useContext(CaiPopupContext)
    const controlsStyles = useMemo(
      () => ({
        color: 'var(--color-primary)',
        fontSize: fullScreen ? 16 : 24,
      }),
      [fullScreen],
    )
    if (fullScreen) {
      return (
        <div className="d-flex align-items-center px-sm-3 h-100">
          <div className="d-flex align-items-center h-100">
            {!handleCompleteActivity && (
              <HeaderButtons
                flow={flow}
                newChat={newChat}
                setMessagesMap={setMessagesMap}
              />
            )}
            <Divider type="vertical" />
            <Notification newChat={newChat} setMessagesMap={setMessagesMap} />
          </div>

          {handleFullSize && !isMobile && (
            <>
              <Divider type="vertical" />
              <Tooltip message="Reduce window">
                <i
                  ref={sizeBtnRef}
                  className="icofont-rounded-expand fs-4 nav-link d-lg-block"
                  role="button"
                  style={controlsStyles}
                  onClick={handleFullSize}
                ></i>
              </Tooltip>
            </>
          )}
          <Divider type="vertical" />

          <i
            className="icofont-close nav-link d-lg-block fs-5"
            role="button"
            style={controlsStyles}
            onClick={handleShow}
          ></i>
        </div>
      )
    }

    return (
      <div className="d-flex align-items-end gap-1 flex-column mt-2">
        <div className="d-flex align-items-center">
          <HeaderButtons
            flow={flow}
            newChat={newChat}
            setMessagesMap={setMessagesMap}
          />
          <Notification newChat={newChat} setMessagesMap={setMessagesMap} />
        </div>

        <div className="d-flex gap-1 align-items-center ">
          <Tooltip message="Move window">
            <i
              ref={draggingBtnRef}
              className="icofont-drag nav-link d-none d-lg-block dragIndicator"
              style={{
                cursor: 'grab',
                fontSize: 18,
                color: 'var(--color-primary)',
              }}
            ></i>
          </Tooltip>

          <Tooltip message="Reduce window">
            <i
              ref={sizeBtnRef}
              className="icofont-rounded-expand nav-link d-none d-lg-block"
              role="button"
              style={controlsStyles}
              onClick={handleFullSize}
            ></i>
          </Tooltip>

          <i
            className="icofont-close nav-link d-none d-lg-block"
            role="button"
            style={controlsStyles}
            onClick={handleShow}
          ></i>
        </div>
      </div>
    )
  },
)

export const CaiChatHeader = memo(
  ({
    handleShow,
    fullScreen,
    handleCompleteActivity,
    newChat,
    setMessagesMap,
    handleFullSize,
  }: CaiChatHeaderProps) => {
    const { flow } = useContext(CaiPopupContext)
    const { t } = useTranslation()

    return (
      <div
        className="chat-header"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e8e8e8',
          padding: '0 8px',
        }}
      >
        <div className="d-flex">
          <HeaderLogo handleShow={handleShow} />
          <HeaderTitle fullScreen={fullScreen} t={t} />
        </div>

        <HeaderControls
          flow={flow}
          fullScreen={fullScreen}
          handleCompleteActivity={handleCompleteActivity}
          handleFullSize={handleFullSize}
          handleShow={handleShow}
          newChat={newChat}
          setMessagesMap={setMessagesMap}
        />
      </div>
    )
  },
)
