import { Dispatch, SetStateAction, useContext, useState } from 'react'
import Tooltip from '../UI/Tooltip'
import { CaiBar } from '../common/CaiBar'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import CAIIcon from '../CaiIcon'
import STTButton from '../common/STTButton'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'

interface CaiChatInputProps {
  onMessageSend: (txtMessage: string, hidden: boolean) => void
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  onInputTyping?: () => void
}

export const CaiChatInput = ({
  onMessageSend,
  show,
  setShow,
  onInputTyping,
}: CaiChatInputProps) => {
  const { aiChatIconRef } = useContext(CaiPopupContext)
  const { t } = useTranslation()
  const [txtMessage, setTxtMessage] = useState('')

  const handleSendMessage = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget.value.trim()) {
      onMessageSend(event.currentTarget.value, false)
      setTxtMessage('')
      if (!show) {
        handleShow()
      }
      onInputTyping?.()
    }
  }

  const handleShow = () => {
    setShow((state) => !state)
  }

  return (
    <CaiBar>
      <div className="d-flex flex-row h-100">
        <div className="d-flex justify-content-center">
          <Tooltip
            message={t('caiPopup.tooltip')}
            overlayTriggerProps={{
              placement: 'bottom',
            }}
          >
            <button
              ref={aiChatIconRef}
              className={`btn me-1 outline-none border-0`}
              onClick={handleShow}
            >
              <CAIIcon isCai={show} style={{ width: 30, height: 30 }} />
            </button>
          </Tooltip>
        </div>

        <span className="d-flex align-items-stretch">
          <Form.Control
            className="border-0 text-light"
            placeholder={t('resources.caiBar.placeholder')}
            value={txtMessage}
            style={{
              minWidth: 300,
              backgroundColor: 'var(--color-on-primary-20)',
            }}
            onKeyUp={handleSendMessage}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTxtMessage(e.target.value)
            }
          />

          <span className="position-relative" style={{ right: -8 }}>
            <STTButton
              {...(!show && { setText: setTxtMessage })}
              passiveColourCode="#F9F6EE"
            />
          </span>
        </span>
      </div>
    </CaiBar>
  )
}
