import { Icon } from '@iconify/react'
import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import { CAI_CHAT_FLOWS } from '../../../constants/cai-chat.constant'
import HeaderButton from './HeaderButton'
import { Message } from 'src/types/session.type'

type Props = {
  newChat: (flow: string) => void
  setMessagesMap: React.Dispatch<
    React.SetStateAction<Record<string, Message[]>>
  >
  flow: string
}

function HeaderButtons(props: Props) {
  const { newChat, flow, setMessagesMap } = props

  const { t } = useTranslation()

  const { newChatRef, librarianRef, feedFwdRef, setLibrarianMsg } =
    useContext(CaiPopupContext)

  return (
    <>
      <HeaderButton
        ref={feedFwdRef}
        className="py-1 px-2 h-100"
        handleClick={() => newChat(CAI_CHAT_FLOWS.FEEDBACK)}
        label={
          <>
            <Icon
              height="24"
              icon="material-symbols:chat-paste-go-outline"
              width="24"
            />
            {!isMobile && t('common.word.communication', 'Communication')}
          </>
        }
        style={{
          borderColor: 'var(--feedback-color)',
          background: 'var(--feedback-color)',
          color: 'black',
          opacity: flow === CAI_CHAT_FLOWS.FEEDBACK ? 1 : 0.7,
        }}
        tooltipProps={{
          message: t(
            'caiPopup.headerButtons.tooltipFeedback.title',
            'Enter FeedForward mode to record and share feedback.',
          ),
        }}
      />

      <HeaderButton
        ref={librarianRef}
        className="py-1 px-2 h-100"
        handleClick={() => {
          newChat(CAI_CHAT_FLOWS.LIBRARIAN)
          setMessagesMap((state) => ({ ...state, librarian: [] }))
          setLibrarianMsg(null)
        }}
        label={
          <>
            <Icon
              height="24"
              icon="material-symbols-light:book-4-spark-outline-rounded"
              width="24"
            />
            {!isMobile &&
              t('caiPopup.headerButtons.tooltipConnect.button', 'Librarian')}
          </>
        }
        style={{
          borderColor: 'var(--librarian-color)',
          background: 'var(--librarian-color)',
          color: 'black',
          opacity: flow === CAI_CHAT_FLOWS.LIBRARIAN ? 1 : 0.7,
        }}
        tooltipProps={{
          message: t(
            'caiPopup.headerButtons.tooltipConnect.title',
            'Connect with the Librarian that can explain any official policies and benefits.',
          ),
        }}
      />

      <HeaderButton
        ref={newChatRef}
        className=" h-100 pr-0 pl-2"
        handleClick={() => newChat(CAI_CHAT_FLOWS.DEFAULT)}
        label={<Icon height="24" icon="ri:chat-new-fill" width="24" />}
        style={{
          color: 'var(--color-primary)',
          border: 0,
          background: 'none',
          opacity: flow === CAI_CHAT_FLOWS.DEFAULT ? 1 : 0.7,
          paddingRight: '0',
        }}
        tooltipProps={{
          message: t(
            'caiPopup.headerButtons.tooltipNewChat.title',
            'Start a new chat on a different topic.',
          ),
        }}
      />
    </>
  )
}

export default React.memo(HeaderButtons)
