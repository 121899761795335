import { fetchEventSource } from '@microsoft/fetch-event-source'
import CAIIcon from '../components/CaiIcon'
import dayjs from 'dayjs'

const HR_PLATFORM_GROUPING_ID = '41d90c32-5daa-4557-82d7-4de4eb010632'

export function appendMessage(message, type, owner, images = []) {
  return { message, type, images, time: new Date().toLocaleTimeString(), owner }
}

export async function getChatData(jwtToken, chatId) {
  const response = await fetch(
    process.env.REACT_APP_CARCH_ENDPOINT + '/chats/' + chatId + '/messages',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
    },
  )
  const responseJson = await response.json()
  if (responseJson.data === null) {
    return []
  }
  return responseJson.data
    ?.filter((item) => !item.hidden)
    .map((item) => ({
      message: item.text,
      type: item.sender === 'user' ? 'sent' : 'received',
      images: [],
      time: dayjs(item.timestamp).format('YYYY-MM-DD h:mm A'),
      owner: item.sender,
      avatar: CAIIcon,
    }))
}

export async function createChat(
  jwtToken,
  userId,
  includeInitialMessage = false,
) {
  const response = await fetch(
    process.env.REACT_APP_CARCH_ENDPOINT + '/chats',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
      body: JSON.stringify({
        user_id: userId,
        include_initial_message: includeInitialMessage,
      }),
    },
  )
  const responseJson = await response.json()
  return responseJson.data
}

export async function endChat(jwtToken, chatId, agentName, agentScope) {
  await fetch(
    process.env.REACT_APP_CARCH_ENDPOINT + '/chats/' + chatId + '/end',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
      body: JSON.stringify({
        agent_name: agentName,
        scope: agentScope,
        collection: 'main', // TODO: (tyler) need dynamic value
      }),
    },
  )
}

export async function createChatMessage(
  jwtToken,
  chatId,
  message,
  sender,
  hidden = false,
) {
  await fetch(
    process.env.REACT_APP_CARCH_ENDPOINT + '/chats/' + chatId + '/messages',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
      body: JSON.stringify({
        text: message,
        sender: sender,
        hidden: hidden,
      }),
    },
  )
}

export async function streamPrompt(
  onResponse,
  onOpen,
  onEnd,
  jwtToken,
  roleName,
  scope = 'default',
  attachmentIds,
  chatId,
  txtMessage,
  hidden = false,
  context = '',
  endpoint = '/prompt/stream',
  userId,
  organizationId,
  canvas = '',
) {
  // TODO (Quang) add canvas from action area
  attachmentIds.push(HR_PLATFORM_GROUPING_ID)
  await fetchEventSource(process.env.REACT_APP_CARCH_STREAM + endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    timeout: 120000,
    body: JSON.stringify({
      role_name: roleName,
      scope: scope,
      attachment_ids: attachmentIds,
      chat_id: chatId,
      query: txtMessage,
      canvas: canvas,
      hidden: hidden,
      context: context,
      user_id: userId,
      organisation_id: organizationId,
    }),
    onmessage(ev) {
      if (ev.data !== '') {
        const parsed = JSON.parse(ev.data)
        if (ev.event === 'canvas') {
          onResponse(parsed.data)
        } else {
          onResponse(parsed.data)
        }
      }
    },
    onopen(_) {
      onOpen()
    },
    onclose() {
      onEnd()
    },
    onerror(err) {
      throw err
    },
  })
}

export async function sendPrompt(
  jwtToken,
  roleName,
  attachmentIds,
  chatId,
  txtMessage,
  hidden = false,
  context = '',
  organisationId = undefined,
) {
  attachmentIds.push(HR_PLATFORM_GROUPING_ID)
  const response = await fetch(
    process.env.REACT_APP_CARCH_ENDPOINT + '/prompt/carch',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 120000,
      body: JSON.stringify({
        role_name: roleName,
        attachment_ids: attachmentIds,
        chat_id: chatId,
        query: txtMessage,
        hidden: hidden,
        context: context,
        organisationId,
      }),
    },
  )
  const responseJson = await response.json()
  return responseJson.data
}

export async function getSummaryChat(jwtToken, chatId) {
  const response = await fetch(
    `${process.env.REACT_APP_CARCH_ENDPOINT}/intercom/summary/chat?chat_id=${chatId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
    },
  )
  const responseJson = await response.json()
  return responseJson.data.summary
}

export async function getSummaryCanvas(jwtToken, text, formatType = '') {
  const response = await fetch(
    `${process.env.REACT_APP_CARCH_ENDPOINT}/intercom/summary/text`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        text,
        format_type: formatType,
      }),
      timeout: 20000,
    },
  )
  const responseJson = await response.json()
  return responseJson?.data?.summary
}

export async function getSummaryText(jwtToken, message) {
  const response = await fetch(
    `${process.env.REACT_APP_CARCH_ENDPOINT}/intercom/summary/text?text=${message}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
    },
  )
  const responseJson = await response.json()
  return responseJson.data.summary
}

export async function searchEntity(jwtToken, userId, name, organisationId) {
  const response = await fetch(
    `${process.env.REACT_APP_CARCH_ENDPOINT}/intercom/identify?user_id=${userId}&name=${name}&organisation_id=${organisationId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
    },
  )
  const responseJson = await response.json()
  return responseJson.data
}

export async function submitFeedback(
  jwtToken,
  senderId,
  targetId,
  targetType,
  feedback,
  anonymous = false,
  language = 'en',
  stsOrganisationId,
) {
  let endpoint = ''
  if (anonymous) {
    endpoint = '/intercom/submit/anonymous'
  } else {
    endpoint = '/intercom/submit/direct'
  }
  const response = await fetch(
    `${process.env.REACT_APP_CARCH_ENDPOINT}${endpoint}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
      body: JSON.stringify({
        sender_id: senderId,
        target: {
          target_id: targetId,
          target_type: targetType,
          organisation_id: stsOrganisationId,
        },
        feedback: feedback,
        language: language,
      }),
    },
  )
  const responseJson = await response.json()
  return responseJson.data
}

export async function getDaySentiment(jwtToken, day, organisationId) {
  const response = await fetch(
    `${process.env.REACT_APP_CARCH_ENDPOINT}/intercom/happiness/day?day=${day}&organisation_id=${organisationId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
    },
  )
  const responseJson = await response.json()
  return responseJson.data
}

export async function submitSentiment(
  jwtToken,
  sentiment,
  day,
  organisationId,
) {
  const response = await fetch(
    `${process.env.REACT_APP_CARCH_ENDPOINT}/intercom/happiness/day`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
      body: JSON.stringify({
        sentiment: sentiment,
        day: day,
        organisation_id: organisationId,
      }),
    },
  )
  const responseJson = await response.json()
  return responseJson.data
}

export async function submitReport(jwtToken, user_id, chatId, comments) {
  const response = await fetch(
    `${process.env.REACT_APP_CARCH_ENDPOINT}/chats/${chatId}/report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
      body: JSON.stringify({
        user_id: user_id,
        comments: comments,
      }),
    },
  )
  const responseJson = await response.json()
  return responseJson.data
}

export const isActiveChat = (messageMap) => {
  const mainChats = messageMap?.main
  if (!mainChats) {
    return false
  }
  // if in main chat, there is a second message from owner cai and type is received then it is active chat
  return mainChats.some((chat, index) => chat?.type === 'sent' && index > 0)
}
