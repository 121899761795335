import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'
import { streamPrompt } from '../utils/platform'

const useStreamPrompt = (chatReducer, onAwaitingResponseChange) => {
  const onStreamResponse = (data, flow) => {
    const { canvas, response, references } = data || {}

    if (canvas && flow === CAI_CHAT_FLOWS.FEEDBACK) {
      onAwaitingResponseChange(false)
      chatReducer(
        {
          type: 'UPDATE_CANVAS',
          payload: { response: canvas },
        },
        flow,
      )
      return
    }

    if (!response) return
    chatReducer(
      {
        type: 'UPDATE_LAST_MESSAGE',
        payload: { response, references },
      },
      flow,
    )
  }

  const onStreamOpen = (flow) =>
    chatReducer({ type: 'INIT_RESPONSE', payload: {} }, flow)

  const onStreamEnd = () => onAwaitingResponseChange(false)

  const sendStreamPrompt = async (
    auth,
    roleName,
    scope,
    attachmentsId,
    chatId,
    message,
    hidden,
    messageContext,
    flow,
    endpoint,
    userId,
    organizationId,
    canvas,
  ) => {
    try {
      onAwaitingResponseChange(true)
      await streamPrompt(
        (data) => onStreamResponse(data, flow),
        () => onStreamOpen(flow),
        onStreamEnd,
        auth?.token,
        roleName,
        scope,
        attachmentsId,
        chatId,
        message,
        hidden,
        messageContext,
        endpoint,
        userId,
        organizationId,
        canvas,
      )
    } catch (e) {
      console.log(e)
    } finally {
      onAwaitingResponseChange(false)
    }
  }

  return { sendStreamPrompt }
}

export default useStreamPrompt
