import { Fragment, useLayoutEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import Draggable from 'react-draggable'

interface ResizeableWrapperProps {
  children: React.ReactNode
  fullScreen: boolean
}

interface ResizeState {
  isResizing: boolean
  startX: number
  startY: number
  startWidth: number
  startHeight: number
}

interface ResizeConstraints {
  minWidth: number
  maxWidth: number
  minHeight: number
  maxHeight: number
}

export const ResizeableWrapper: React.FC<ResizeableWrapperProps> = ({
  children,
  fullScreen,
}: {
  children: React.ReactNode
  fullScreen: boolean
}) => {
  const IsDraggable = fullScreen ? Fragment : Draggable

  useLayoutEffect(() => {
    if (!fullScreen) {
      const resizer = document.querySelector<HTMLElement>('.resizer-left-top')
      const resizable = document.querySelector<HTMLElement>('.resizable')
      const content = document.querySelector<HTMLElement>('.content')

      if (!resizer || !resizable || !content) return

      const resizeState: ResizeState = {
        isResizing: false,
        startX: 0,
        startY: 0,
        startWidth: 0,
        startHeight: 0,
      }

      const constraints: ResizeConstraints = {
        minWidth: 400,
        maxWidth: window.innerWidth * 0.8,
        minHeight: 500,
        maxHeight: window.innerHeight * 0.9,
      }

      const handleMouseDown = (e: MouseEvent) => {
        resizeState.isResizing = true
        resizeState.startX = e.clientX
        resizeState.startY = e.clientY
        resizeState.startWidth = parseInt(
          document.defaultView?.getComputedStyle(resizable).width || '0',
          10,
        )
        resizeState.startHeight = parseInt(
          document.defaultView?.getComputedStyle(resizable).height || '0',
          10,
        )
        e.preventDefault()
      }

      const handleMouseMove = (e: MouseEvent) => {
        if (!resizeState.isResizing) return

        const width = Math.max(
          constraints.minWidth,
          Math.min(
            resizeState.startWidth - (e.clientX - resizeState.startX),
            constraints.maxWidth,
          ),
        )
        const height = Math.max(
          constraints.minHeight,
          Math.min(
            resizeState.startHeight - (e.clientY - resizeState.startY),
            constraints.maxHeight,
          ),
        )

        resizable.style.width = `${width}px`
        resizable.style.height = `${height}px`
        content.style.width = `${width}px`
        content.style.height = `${height}px`
      }

      const handleMouseUp = () => {
        resizeState.isResizing = false
      }

      resizer.addEventListener('mousedown', handleMouseDown)
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)

      return () => {
        resizer.removeEventListener('mousedown', handleMouseDown)
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [fullScreen])

  return (
    <IsDraggable {...(!fullScreen && { handle: '.dragIndicator' })}>
      <div
        className="resizable position-fixed overflow-hidden d-flex flex-column"
        id="resizable"
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          width: fullScreen ? (isMobileOnly ? '100%' : '80%') : '400px',
          height: fullScreen ? (isMobileOnly ? '100%' : '90%') : '85vh',
          backgroundColor: '#fff',
          boxShadow: fullScreen
            ? '0 4px 8px rgba(0, 0, 0, 0.5)'
            : '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
          border: isMobileOnly ? '1px solid black' : 'none',
          borderRadius: fullScreen ? '10px' : '8px',
        }}
      >
        {children}
      </div>
    </IsDraggable>
  )
}
