import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { Suspense, useContext, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { CaiChatHeader, ResizeableWrapper } from 'src/components/CaiChat'
import {
  formatTime,
  getFirstCaiIntro,
  getSubsequentCaiIntro,
} from 'src/components/CaiChat/utils'
import ChatAgent from 'src/components/ChatAgent'
import { CaiChatInput } from 'src/components/ChatAgent/CaiChatInput'
import { antdLocales } from 'src/constants/i18n.constant'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import { AuthContext } from '../../components/Auth/AuthProvider'
import CaiTour from '../../components/CaiPopup/CaiTour'
import { CAI_CHAT_FLOWS } from '../../constants/cai-chat.constant'
import { CaiChatContext } from '../../context/CaiChatContext'
import { NotificationContext } from '../../context/NotificationContext'
import {
  useGetAllTasksAssignedToUserQuery,
  useUpdateCaiInstanceMutation,
  useUpdatePreviousBubbleIdMutation,
} from '../../gql/graphql'
import useStreamPrompt from '../../hooks/useStreamPrompt'
import {
  BACK_BUBBLE_BUTTON,
  BUBBLE_DATA,
  findParentId,
  getSubButtons,
} from '../../utils/bubbles'
import {
  appendMessage,
  createChat,
  createChatMessage,
  endChat,
  getChatData,
  getSummaryChat,
  searchEntity,
  submitFeedback,
  submitReport,
} from '../../utils/platform'

function CaiChat({
  handleShow,
  handleFullSize = (_) => {},
  fullScreen,
  showInput = false,
}) {
  const { t, i18n } = useTranslation()
  //utils
  const history = useHistory()

  //stores
  const { userInfo, auth, setUserInfo } = useContext(AuthContext)
  const { message, resetMessage } = useContext(CaiChatContext)
  const userId = userInfo?.id
  const { sessionId, handleCompleteActivity } = useContext(NotificationContext)
  const { flow, setFlow, tourOpen, setTourOpen, show, setShow } =
    useContext(CaiPopupContext)

  //states
  const [promptContext, setPromptContext] = useState()
  const [awaitingResponse, setAwaitingResponse] = useState(false)
  const [currentBubbles, setCurrentBubbles] = useState(BUBBLE_DATA.buttons)

  const [bubblesLoading, setBubblesLoading] = useState(false)
  const [messagesMap, setMessagesMap] = useState({
    main: [],
    librarian: [],
    feedback: [],
    session: [],
  })
  const [initialFeedback, setInitialFeedback] = useState('')
  const [initialFeedbackLoading, setInitialFeedbackLoading] = useState(false)
  const [audioEnabled, setAudioEnabled] = useState(true)

  //apis
  const [updateCaiInstance] = useUpdateCaiInstanceMutation()
  const [updatePreviousBubbleId] = useUpdatePreviousBubbleIdMutation()
  const { data: tasks, loading: tasksLoading } =
    useGetAllTasksAssignedToUserQuery({ variables: { userId } })

  //vars
  const hasInitialised = useRef(false)
  const previousBubbles = useRef([])
  const agentScope = useRef(userInfo?.caiInstance?.currentScope)
  const chatId = useRef()
  const userName = useRef('friend')
  const tasksString = useRef('')
  const activeBubble = useRef('default')
  const chatSessionData = useRef({})
  const agentRole = 'cai'
  const chatIds = useRef({
    main: userInfo?.caiInstance?.chatId,
    librarian: null,
    feedback: null,
    session: null,
  })
  const isEmployeeMode = localStorage.getItem('isEmployeeMode') === 'true'
  const shouldShowTour = userInfo?.isFirstLogin && fullScreen
  const shouldShowChat = isEmployeeMode || sessionId

  //funcs
  const messagesReducer = (action, flow = CAI_CHAT_FLOWS.DEFAULT) => {
    switch (action.type) {
      case 'INIT_RESPONSE': {
        const newMessage = { message: '', type: 'received', owner: agentRole }
        setMessagesMap((state) => ({
          ...state,
          [flow]: [...state[flow], newMessage],
        }))
        break
      }
      case 'ADD_MESSAGE': {
        setMessagesMap((state) => ({
          ...state,
          [flow]: [...state[flow], action.payload],
        }))
        break
      }
      case 'UPDATE_LAST_MESSAGE': {
        setMessagesMap((prevState) => {
          const updatedState = { ...prevState }
          const flowMessages = get(updatedState, flow, [])

          if (!flowMessages.length) {
            return updatedState
          }

          const lastMessageIndex = flowMessages.length - 1
          const lastMessage = flowMessages[lastMessageIndex] ?? {}
          const msgOfLastMsg = get(lastMessage, 'message', '')

          const d = dayjs()
          const formattedDate = d.format('YYYY-MM-DD')
          const formattedTime = d.format('hh:mm A')

          const updatedMessage = {
            ...lastMessage,
            time: `${formattedDate} ${formattedTime}`,
            message: msgOfLastMsg + action.payload.response,
            references: action.payload?.references?.map((reference) => ({
              ...reference,
              uri:
                reference.uri === 'interface_guide.md'
                  ? `${process.env.REACT_APP_ASSETS_ENDPOINT}/static/files/product_guides/${reference.uri}`
                  : `${process.env.REACT_APP_ASSETS_ENDPOINT}/documents/${userInfo?.organization?.id}/${reference.uri}`,
            })),
          }
          updatedState[flow][lastMessageIndex] = updatedMessage
          return updatedState
        })
        break
      }
      case 'UPDATE_CANVAS': {
        const canvasResp = get(action, 'payload.response', '')
        setInitialFeedback(canvasResp)
        break
      }
      default:
        break
    }
  }

  const { sendStreamPrompt } = useStreamPrompt(
    messagesReducer,
    setAwaitingResponse,
  )

  const onInputTyping = () =>
    setCurrentBubbles((state) => state.filter((button) => button.persistent))

  const onReport = async (details) =>
    submitReport(
      auth?.token,
      userInfo.stsUserId,
      chatIds.current[flow],
      details,
    )

  const onMessageSend = async (txtMessage, hidden = false) => {
    if (!hidden) {
      const newMessage = appendMessage(txtMessage, 'sent', 'user')
      messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage }, flow)
    }
    const chatId = await getChatId()
    let currentPromptContext = promptContext
    if (agentScope.current === 'focus') {
      currentPromptContext = `${tasksString.current}\n${currentPromptContext}`
    }
    if (activeBubble.current == 'official_save') {
      await handleBubblePrompt(activeBubble.current, txtMessage)
    } else {
      await sendStreamPrompt(
        auth,
        flow === 'feedback' ? 'feedback_gather' : 'cai',
        agentScope.current,
        [userInfo?.organization?.id],
        chatId,
        txtMessage,
        hidden,
        currentPromptContext,
        flow,
        sessionId && flow === CAI_CHAT_FLOWS.SESSION
          ? `/prompt/session/${sessionId}`
          : undefined,
        userId,
        userInfo?.organization?.stsOrganisationId,
        initialFeedback,
      )
    }
  }

  const doUpdateCaiInstance = async (chatId, currentScope, bubbleId = 1) => {
    const input = {
      id: userInfo?.caiInstance?.id,
      chatId,
      cogarchRole: userInfo?.caiInstance?.cogarchRole,
      currentScope: currentScope,
    }

    // only set this property when bubbleId = null ==> that mean you want to reset bubbleId in database to null
    if (bubbleId === null) input.bubbleId = null

    await updateCaiInstance({
      variables: { userId, input },
      onCompleted: (data) => {
        setUserInfo({
          ...userInfo,
          caiInstance: data.updateCaiInstance.data.caiInstance,
        })
      },
    })
  }

  const doUpdateCaiInstanceBubbleId = async (bubbleId) => {
    return await updatePreviousBubbleId({
      variables: {
        userId,
        caiInstanceId: userInfo?.caiInstance?.id,
        bubbleId: bubbleId,
      },
      onCompleted: (data) => {
        setUserInfo({
          ...userInfo,
          caiInstance: data.updatePreviousBubbleId.data.caiInstance,
        })
      },
    })
  }

  const setScopeContext = () => {
    const now = new Date()
    const currentTime = formatTime(now.toISOString()) // Use the formatTime function
    let context = `User's name: ${userName.current}\nCurrent time: ${currentTime}\n\n`
    setPromptContext(context)
  }

  const createChatId = async (flow) => {
    let data = null
    if (flow === CAI_CHAT_FLOWS.DEFAULT) {
      data = await createChat(auth?.token, userInfo?.stsUserId, true)
    } else {
      data = await createChat(auth?.token, userInfo?.stsUserId)
    }
    chatIds.current[flow] = data?.chat_id

    return {
      chatId: data?.chat_id,
      initialMessage: data?.initial_message,
    }
  }

  const newChat = async (selectedFlow = CAI_CHAT_FLOWS.DEFAULT) => {
    switch (selectedFlow) {
      case CAI_CHAT_FLOWS.DEFAULT: {
        // set new flow
        setFlow(CAI_CHAT_FLOWS.DEFAULT)
        // if no messages, nothing to do
        if (messagesMap[selectedFlow].length <= 1) return
        // save previous session details
        const previousChatId = chatIds.current[selectedFlow]
        const previousScope = agentScope.current
        // begin new session
        const caiIntro = getSubsequentCaiIntro(userName.current, i18n.language)
        const { chatId: newChatId, initialMessage } =
          await createChatId(selectedFlow)

        await resetChatSession(initialMessage || caiIntro, selectedFlow)
        // only create the first message if there is no initial message
        if (!initialMessage) {
          await createChatMessage(auth?.token, newChatId, caiIntro, 'cai')
        }
        await doUpdateCaiInstance(newChatId, agentScope.current, null)
        // cleanup old session
        endChat(auth?.token, previousChatId, agentRole, previousScope)
        return
      }
      case CAI_CHAT_FLOWS.FEEDBACK: {
        setFlow(CAI_CHAT_FLOWS.FEEDBACK)
        if (messagesMap[selectedFlow].length === 1) return
        const caiIntro = t(
          'feedbacks.textIntro',
          'Hello, welcome to the FeedForward space! How can I assist you today?',
          {
            user: userInfo?.employeeDetails?.firstName,
          },
        )
        await resetChatSession(caiIntro, selectedFlow)
        const { chatId: newChatId } = await createChatId(selectedFlow)
        await createChatMessage(auth?.token, newChatId, caiIntro, 'cai')
        const previousChatId = chatIds.current[selectedFlow]
        const previousScope = agentScope.current
        endChat(auth?.token, previousChatId, agentRole, previousScope)
        return
      }
      case CAI_CHAT_FLOWS.LIBRARIAN: {
        await createChatId(CAI_CHAT_FLOWS.LIBRARIAN)
        setFlow(CAI_CHAT_FLOWS.LIBRARIAN)
        agentScope.current = 'questions'
        return
      }
      case CAI_CHAT_FLOWS.SESSION: {
        setFlow(selectedFlow)
        return
      }
      default: {
        toast.error(t('common.toast.error'))
        console.error('Invalid selectedFlow:', selectedFlow)
      }
    }
  }

  const getChatId = async () => {
    if (!chatIds.current[flow]) await createChatId(flow)
    return chatIds.current[flow]
  }

  function createMessage(message, type, owner) {
    const d = dayjs()
    const formattedDate = d.format('YYYY-MM-DD')
    const formattedTime = d.format('hh:mm A')

    return {
      message: message,
      type: type,
      images: [],
      owner: owner,
      time: `${formattedDate} ${formattedTime}`,
      isFirstMsg: true,
    }
  }

  const handleBubblesClick = async (bubbles) => {
    setBubblesLoading(true)
    activeBubble.current = bubbles.id
    if (bubbles.scope) {
      agentScope.current = bubbles.scope
      await doUpdateCaiInstance(chatIds.current[flow], bubbles.scope)
      setScopeContext(bubbles.scope)
    }

    let currentBubblesUpdated = []
    // set next bubbles if any
    if (bubbles.sub_buttons) {
      previousBubbles.current = [...previousBubbles.current, currentBubbles]
      currentBubblesUpdated = [...bubbles.sub_buttons, BACK_BUBBLE_BUTTON]
    } else if (bubbles.id === 'back') {
      currentBubblesUpdated = [...previousBubbles.current].pop()
      previousBubbles.current = previousBubbles.current.slice(0, -1)
    } else if (!bubbles.persistent) {
      currentBubblesUpdated = []
    }
    setCurrentBubbles(currentBubblesUpdated)

    if (bubbles.id === 'back') {
      const bubbleId =
        currentBubblesUpdated?.length > 0
          ? findParentId(currentBubblesUpdated?.[0]?.id, BUBBLE_DATA.buttons)
          : null
      await doUpdateCaiInstanceBubbleId(bubbleId)
    } else {
      await doUpdateCaiInstanceBubbleId(
        bubbles?.sub_buttons?.length > 0 ? bubbles.id : null,
      )
    }

    if (bubbles.link) {
      // follow link
      history.push(
        `${userInfo.employeeDetails.role.name.toLowerCase()}-dashboard`,
      )
    } else if (bubbles.message || bubbles.images) {
      // else show bubble's message
      const userMessage = appendMessage(bubbles.label, 'sent', 'user')
      messagesReducer({ type: 'ADD_MESSAGE', payload: userMessage }, flow)
      await createChatMessage(
        auth?.token,
        chatIds.current[flow],
        bubbles.label,
        'user',
      )
      const agentMessage = appendMessage(
        bubbles.message,
        'received',
        agentRole,
        bubbles.images,
      )
      messagesReducer({ type: 'ADD_MESSAGE', payload: agentMessage }, flow)
      await createChatMessage(
        auth?.token,
        chatIds.current[flow],
        bubbles.message,
        agentRole,
      )
    }

    await handleBubbleAction(bubbles.id)
    setBubblesLoading(false)
  }

  const handleSendPrompt = async (chatId, message) => {
    if (message) {
      const newMessage = appendMessage(message, 'sent', 'user')
      messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage }, flow)

      sendStreamPrompt(
        auth,
        'cai',
        agentScope.current,
        [userInfo?.organization?.id],
        chatId,
        message,
        false,
        promptContext,
        flow,
      )
        .catch((error) => console.log(error))
        .finally(() => {
          resetMessage()
        })
    }
  }

  const resetChatSession = async (firstMessage, currentFlow) => {
    agentScope.current = 'default'
    setInitialFeedback('')
    setMessagesMap((prevState) => ({
      ...prevState,
      [`${currentFlow || 'main'}`]: [],
    }))
    const newMessage = createMessage(firstMessage, 'received', 'cai')
    messagesReducer(
      { type: 'ADD_MESSAGE', payload: newMessage },
      currentFlow ?? flow,
    )
    setCurrentBubbles(BUBBLE_DATA.buttons)
    previousBubbles.current = []
  }

  // Initialises a chat session
  const initChat = async (message) => {
    if (!hasInitialised.current && chatId) {
      hasInitialised.current = true
      const chatId = await getChatId(flow)
      const chatData = await getChatData(auth?.token, chatId)

      userName.current = `${userInfo?.employeeDetails?.firstName}`
      setScopeContext(agentScope.current)

      if (!chatData?.length) {
        let caiIntro
        if (userInfo?.isFirstLogin) {
          caiIntro = getFirstCaiIntro(userName.current)
        } else {
          caiIntro = getSubsequentCaiIntro(userName.current, i18n.language)
        }
        await resetChatSession(caiIntro)
        await createChatMessage(auth?.token, chatId, caiIntro, 'cai')
      } else {
        chatData.forEach((message) => {
          messagesReducer({ type: 'ADD_MESSAGE', payload: message }, flow)
        })
      }

      handleSendPrompt(chatId, message)
    } else if (hasInitialised.current && chatIds.current[flow]) {
      handleSendPrompt(chatIds.current[flow], message)
    }
  }

  const handleBubbleAction = async (bubbleId) => {
    switch (bubbleId) {
      // (TYLER) MOVING
      case 'official_review': {
        setAwaitingResponse(true)
        getSummaryChat(auth?.token, chatIds.current[flow]).then((summary) => {
          chatSessionData.current.summary = summary
          const newMessage = appendMessage(summary, 'received', 'cai')
          messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage })
          setAwaitingResponse(false)
        })
        break
      }
      case 'official_submit': {
        setCurrentBubbles([])
        setAwaitingResponse(true)
        await submitFeedback(
          auth?.token,
          userId,
          chatSessionData.current.entity.id,
          chatSessionData.current.entity.type,
          chatSessionData.current.summary,
        )
        const message = `Feedback submitted, resetting chat in 5 seconds.`
        const newMessage = appendMessage(message, 'received', 'cai')
        messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage })
        setAwaitingResponse(false)
        setTimeout(async () => {
          await newChat()
        }, 7000)
        break
      }
    }
  }

  const handleBubblePrompt = async (bubbleId, prompt) => {
    switch (bubbleId) {
      case 'official_save': {
        const entity = await searchEntity(
          auth?.token,
          userInfo?.stsUserId,
          prompt,
          userInfo?.organization?.id,
        )
        const message = `I will submit the feedback for ${entity.name}, if correct please press Submit.`
        const newMessage = appendMessage(message, 'received', 'cai')
        messagesReducer({ type: 'ADD_MESSAGE', payload: newMessage })
        chatSessionData.current.entity = entity
        break
      }
    }
  }

  //effects
  useEffect(() => {
    if (!tasksLoading && tasks) {
      const taskBreakdown = tasks.tasksAssignedToUser
        .filter(
          (task) =>
            task.assignee.status === 'IN_PROGRESS' ||
            task.assignee.status === 'TO_DO',
        )
        .map((task) => ({
          name: task.title,
          status: task.assignee.status,
          end_date: formatTime(task.assignee.endDate), // Format the end date
          description: task.description,
        }))
        .reduce(
          (acc, task) => {
            const taskLine = `${task.end_date} ${task.name}: ${task.description}\n`
            if (task.status === 'TO_DO') {
              acc.todo += taskLine
            } else if (task.status === 'IN_PROGRESS') {
              acc.inProgress += taskLine
            }
            return acc
          },
          { todo: 'TODO\n', inProgress: 'IN_PROGRESS\n' },
        )
      tasksString.current = `USER'S TASKS:\n${taskBreakdown.todo}\n${taskBreakdown.inProgress}`
    }
  }, [tasksLoading, tasks])

  // Initialisation logic
  useEffect(() => {
    if (flow === CAI_CHAT_FLOWS.SESSION) return

    if (userInfo?.caiInstance?.previousBubbleId) {
      setCurrentBubbles([
        ...getSubButtons(
          userInfo?.caiInstance?.previousBubbleId,
          BUBBLE_DATA.buttons,
        ),
        BACK_BUBBLE_BUTTON,
      ])
    } else {
      setCurrentBubbles(BUBBLE_DATA.buttons)
    }

    initChat(message)
  }, [flow])

  useEffect(() => {
    if (shouldShowTour) {
      setTimeout(() => {
        setShow(true)
        setTourOpen(true)
        localStorage.setItem('isCaiTourOpen', true)
      }, 0)
    } else if (shouldShowChat) {
      setShow(true)
      if (isEmployeeMode) {
        localStorage.setItem('isEmployeeMode', false)
      }
    }
  }, [])

  return (
    <>
      {showInput && (
        <CaiChatInput
          setShow={setShow}
          show={show}
          onInputTyping={onInputTyping}
          onMessageSend={onMessageSend}
        />
      )}
      {show && (
        <Suspense>
          {fullScreen && (
            <div
              className="position-fixed top-0 start-0 w-100 h-100"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}
              {...(!userInfo?.isFirstLogin && { onClick: handleShow })}
            />
          )}
          <ResizeableWrapper fullScreen={fullScreen}>
            {!fullScreen && <div className="resizer resizer-left-top"></div>}
            <div className="content d-flex flex-column overflow-hidden">
              <CaiChatHeader
                flow={flow}
                fullScreen={fullScreen}
                handleCompleteActivity={handleCompleteActivity}
                handleFullSize={handleFullSize}
                handleShow={handleShow}
                newChat={newChat}
                setMessagesMap={setMessagesMap}
              />

              <ChatAgent
                agentScope={agentScope}
                awaitingResponse={awaitingResponse}
                bubblesLoading={bubblesLoading}
                currentBubbles={currentBubbles}
                flow={flow}
                fullScreen={fullScreen}
                initialFeedback={initialFeedback}
                initialFeedbackLoading={initialFeedbackLoading}
                messages={messagesMap[flow] || []}
                moodButtonsEnabled={true}
                newChat={newChat}
                promptContext={promptContext}
                roleName={agentRole}
                setAwaitingResponse={setAwaitingResponse}
                setFlow={setFlow}
                setInitialFeedback={setInitialFeedback}
                setMessagesMap={setMessagesMap}
                tourOpen={tourOpen}
                onBubblesClick={(bubbles) => handleBubblesClick(bubbles, flow)}
                onInputTyping={onInputTyping}
                onReport={onReport}
                onSendMessage={onMessageSend}
              />
              {tourOpen && !isMobile ? (
                <ConfigProvider locale={antdLocales[i18n.language]}>
                  <CaiTour
                    audioEnabled={audioEnabled}
                    didUpdateMsg={awaitingResponse || bubblesLoading}
                    fullScreen={fullScreen}
                    handleFullSize={handleFullSize}
                    newChat={newChat}
                    open={tourOpen}
                    setAudioEnabled={setAudioEnabled}
                    setOpen={setTourOpen}
                    onSessionEnd={() => {
                      console.log('===> close hehe', fullScreen)
                      if (!fullScreen) {
                        handleFullSize(true)
                      }
                      if (userInfo?.isFirstLogin) {
                        localStorage.setItem('isCaiTourOpen', false)
                      }
                      setUserInfo((s) => ({ ...s, isFirstLogin: false }))
                      setFlow(CAI_CHAT_FLOWS.DEFAULT)
                    }}
                  />
                </ConfigProvider>
              ) : null}
            </div>
          </ResizeableWrapper>
        </Suspense>
      )}
    </>
  )
}

export default CaiChat
