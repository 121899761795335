import { lazy } from 'react'
import { Route } from 'react-router-dom'

const OAuthCallback = lazy(
  () => import('../components/Integration/OAuthCallback'),
)

const OrganizationCallback = lazy(
  () => import('../components/Integration/OrganizationCallback'),
)

const OAuthSecondaryAccCallback = lazy(
  () => import('../components/Integration/OAuthSecondaryAccCallback'),
)

const VerificationCallback = lazy(
  () => import('src/components/Integration/VerificationCallback'),
)

export default function OAuthIndex() {
  return (
    <div
      className="main p-2 py-3 p-xl-5 d-flex flex-column"
      style={{ position: 'relative' }}
    >
      <div className="body justify-content-center align-items-center d-flex p-0 p-xl-5">
        <div className="container-xxl">
          <div className="row g-0">
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/oauth/slack/callback`}
              render={(props) => (
                <OAuthCallback {...props} integration={'Slack'} />
              )}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/oauth/slack/organisation/callback`}
              render={(props) => (
                <OrganizationCallback {...props} integration={'Slack'} />
              )}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/oauth/microsoft/callback`}
              render={(props) => (
                <OAuthCallback {...props} integration={'Microsoft'} />
              )}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/oauth/microsoft/organisation/callback`}
              render={(props) => (
                <OrganizationCallback {...props} integration={'Microsoft'} />
              )}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/oauth/slack/secondary-account/callback`}
              render={(props) => (
                <OAuthSecondaryAccCallback {...props} integration={'Slack'} />
              )}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/oauth/microsoft/secondary-account/callback`}
              render={(props) => (
                <OAuthSecondaryAccCallback
                  {...props}
                  integration={'Microsoft'}
                />
              )}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/auth/user/secondary-account`}
              render={(props) => <VerificationCallback {...props} />}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
