import { useTranslation } from 'react-i18next'
import { CAI_CHAT_FLOWS } from '../../../constants/cai-chat.constant'
import Tooltip from '../../UI/Tooltip'
import { Modal } from 'antd'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Icon } from '@iconify/react'

const BUTTONS = [
  {
    id: 'anonymous',
    title: 'caiPopup.feedbackButton.button.anonymous.title',
    tooltip: 'caiPopup.feedbackButton.button.anonymous.tooltip',
  },
  {
    id: 'direct',
    title: 'caiPopup.feedbackButton.button.direct.title',
    tooltip: 'caiPopup.feedbackButton.button.direct.tooltip',
  },
]

export default function FeedbackButtons({
  form,
  setFlow,
  handleFeedbackButtonsClick,
  disabled,
}) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')

  const handleSubmit = async (values) => {
    setFlow(CAI_CHAT_FLOWS.DEFAULT)
    await handleFeedbackButtonsClick(values)
  }

  const handleButtonClick = (id) => {
    setId(id)
    setOpen(true)
  }

  const handleOk = async (id) => {
    try {
      const values = await form.validateFields()
      handleSubmit({ ...values, id })

      toast.success(t('notifcation.feedFwd.messageSent'))
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  return (
    <>
      <div className="d-flex flex-row gap-2 justify-content-end">
        {BUTTONS.map(({ id, title, tooltip }, index) => (
          <Tooltip
            key={title}
            message={
              disabled
                ? 'Please provide a recipient and FeedForward summary before sending.'
                : t(tooltip)
            }
          >
            <span>
              <button
                className="btn"
                disabled={disabled}
                type="submit"
                style={{
                  background: index === 1 ? '#B22222' : 'var(--primary-color)',
                  color: 'white',
                  minWidth: '120px',
                  height: '45px',
                  padding: '0.5em 1em',
                  fontSize: '1rem',
                  border: '1px solid black',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '5px',
                  opacity: disabled ? 0.6 : 1,
                  pointerEvents: disabled ? 'none' : 'auto',
                }}
                onClick={() => handleButtonClick(id)}
              >
                {t(title)}
              </button>
            </span>
          </Tooltip>
        ))}
      </div>
      <Modal
        centered
        destroyOnClose
        open={open}
        title={t('common.word.confirmSend')}
        onCancel={() => setOpen(false)}
        onOk={() => handleOk(id)}
      >
        <div>
          {id === 'anonymous' ? (
            <div>
              {t('resources.feedbacks.modal.content', {
                name: t(BUTTONS[0].title),
              })}
            </div>
          ) : (
            <div>
              {t('resources.feedbacks.modal.content', {
                name: t(BUTTONS[1].title),
              })}
            </div>
          )}
          <div className="mt-1 text-muted">
            <span>
              Note: Remember you can use the Rewrite function{' '}
              <Icon
                color="var(--color-primary)"
                height="24"
                icon="ph:magic-wand-fill"
                type="button"
                width="24"
              />{' '}
              below
            </span>
          </div>
        </div>
      </Modal>
    </>
  )
}
