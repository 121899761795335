import dayjs from 'dayjs'
import { t } from 'i18next'
import { toast } from 'react-toastify'
import { OrganizationCategory, Role, User, WorkingRole } from 'src/gql/graphql'
import { parseJWT } from 'src/utils/auth'

export const transformAuthData = (data: User) => {
  const { userOrganizations = [], roles: permissions, ...rest } = data
  const organizationStorageId = localStorage.getItem('organizationId') || null

  if (!userOrganizations?.length) {
    toast.error(
      t('common.toast.error', 'Something went wrong. Please try again later.'),
    )
  }

  const sortedUserOrg = userOrganizations?.filter((userOrg) => {
    if (process.env.REACT_APP_ENV === 'prod') {
      return userOrg.organization.category !== OrganizationCategory.Personal
    }
    return userOrg
  })

  if (!sortedUserOrg?.length) {
    toast.error(
      t('common.toast.error', 'Something went wrong. Please try again later.'),
    )
    return
  }

  let activeOrganization = sortedUserOrg.find(
    (userOrg) => userOrg.organization.id === organizationStorageId,
  )?.organization

  if (!activeOrganization) {
    activeOrganization = sortedUserOrg[0].organization
  }

  localStorage.setItem('organizationId', activeOrganization.id)

  const orgRoles = permissions?.find(
    (p) => p.organizationId === activeOrganization.id,
  )?.workingRoles

  const result = {
    ...rest,
    userOrganizations: sortedUserOrg,
    organization: activeOrganization,
    roles: orgRoles,
    permissions,
  }
  console.log(result)

  handleCAIAutoOpen(result.employeeDetails?.role as WorkingRole)

  return result
}

export const checkTokenExpirationAndLogout = (
  token: string,
  logout: () => void,
) => {
  try {
    const decodedToken: { exp: number } = parseJWT(token)

    const currentTime = dayjs()
    const expirationTime = dayjs.unix(decodedToken.exp)

    if (expirationTime.isBefore(currentTime)) {
      toast.error('auth.authProvider.toast.error')
      logout()
    }
  } catch (error) {
    console.error('Failed to decode token:', error)
    logout()
  }
}

//JM wants if the user is in employee mode, the CAI should be opened automatically
const handleCAIAutoOpen = (role: WorkingRole) => {
  const isEmployee = role.name === Role.Employee
  localStorage.setItem('isEmployeeMode', isEmployee.toString())
}
