import {
  useGeneratePresignedGetUrlMutation,
  useGeneratePresignedPutUrlMutation,
  useGeneratePresignedDeleteUrlMutation,
} from 'src/gql/graphql'

export const usePresignedUrls = () => {
  const [generatePresignedPutUrl] = useGeneratePresignedPutUrlMutation()
  const [generatePresignedDeleteUrl] = useGeneratePresignedDeleteUrlMutation()
  const [generatePresignedGetUrl] = useGeneratePresignedGetUrlMutation()

  const uploadFileToS3 = async (
    file: File,
    folder: string,
    fileName: string,
  ) => {
    try {
      const { data } = await generatePresignedPutUrl({
        variables: { fileName, folder },
      })

      if (!data?.generatePresignedPutUrl) {
        throw new Error('Error generating presigned PUT URL')
      }

      const { url } = data.generatePresignedPutUrl

      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      })

      return data.generatePresignedPutUrl
    } catch (error) {
      console.error('Error uploading file:', error)
      throw new Error('Error uploading file')
    }
  }

  const deleteFileFromS3 = async (key: string) => {
    if (!key) return
    try {
      const { data } = await generatePresignedDeleteUrl({
        variables: { existingKey: key },
      })

      if (!data?.generatePresignedDeleteUrl) {
        throw new Error('Error generating presigned DELETE URL')
      }

      const { url } = data.generatePresignedDeleteUrl

      await fetch(url, {
        method: 'DELETE',
      })

      return true
    } catch (error) {
      console.error('Error deleting file:', error)
      throw new Error('Error deleting file')
    }
  }

  const generateS3DownloadURL = async (key: string) => {
    if (!key) return
    try {
      const { data } = await generatePresignedGetUrl({
        variables: { existingKey: key },
      })

      if (!data?.generatePresignedGetUrl) {
        throw new Error('Error generating presigned GET URL')
      }

      const { url } = data.generatePresignedGetUrl

      return url
    } catch (error) {
      console.error('Error generating download url:', error)
      throw new Error('Error generating download url')
    }
  }

  return {
    uploadFileToS3,
    deleteFileFromS3,
    generateS3DownloadURL,
  }
}
