import { Icon } from '@iconify/react'
import { ConfigProvider, Form, Radio, Select, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib'
import get from 'lodash/get'
import { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'src/components/Auth/AuthProvider'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'
import { CommunicationContext } from 'src/context/CommunicationContext'
import {
  Team,
  User,
  useGetEmployeesQuery,
  useGetTeamsQuery,
} from 'src/gql/graphql'

const { Option } = Select

interface FeedFwdModalProp {
  form: FormInstance
  flow?: string
  currentFeedback: string
}

const FeedFwdCanvasHeader: FC<FeedFwdModalProp> = ({
  form,
  flow,
  currentFeedback,
}) => {
  // const [form] = Form.useForm()
  const { userInfo } = useContext(AuthContext)
  const { t } = useTranslation()
  const organizationId = userInfo?.organization?.id
  const roleId = get(userInfo, 'employeeDetails.role.id', '')
  const skip = !userInfo || flow !== CAI_CHAT_FLOWS.FEEDBACK
  const { sendRef, setSelectedReceiver, setFeedbackModal } =
    useContext(CommunicationContext)
  const isButtonDisabled = !form.getFieldValue('targetId') || !currentFeedback

  const { data: employees } = useGetEmployeesQuery({
    variables: { roleId, organizationId },
    skip,
  })

  const { data: teams } = useGetTeamsQuery({
    variables: { organizationId },
    skip,
  })

  const sortedEmployees = useMemo(() => {
    return [...(employees?.employees || [])].sort((a, b) => {
      const firstNameA = a?.employeeDetails?.firstName || ''
      const firstNameB = b?.employeeDetails?.firstName || ''
      return firstNameA.localeCompare(firstNameB)
    })
  }, [employees?.employees])

  return (
    <Form
      form={form}
      initialValues={{
        feedbackTarget: 'person',
      }}
      style={{
        marginBottom: '15px',
        paddingLeft: '5px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        // justifyContent: isMobile ? 'space-between' : 'flex-start',
      }}
    >
      <div className="d-flex w-100 gap-1">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'var(--color-primary)',
              borderRadius: 15,
            },
            components: {
              Radio: {
                buttonSolidCheckedBg: 'var(--color-primary)',
                buttonSolidCheckedHoverBg: 'var(--color-primary)',
              },
            },
          }}
        >
          <Form.Item
            name="feedbackTarget"
            style={{ marginBottom: 0, width: 'auto' }}
          >
            <Radio.Group
              buttonStyle="solid"
              optionType="button"
              options={[
                {
                  label: t('caiPopup.feedback.person', 'Person'),
                  value: 'person',
                },
                {
                  label: t('caiPopup.feedback.team', 'Team'),
                  value: 'team',
                },
              ]}
              style={{
                backgroundColor: 'var(--feedback-color)',
              }}
              onChange={() => form.setFieldValue('targetId', undefined)}
            />
          </Form.Item>
        </ConfigProvider>

        <Form.Item
          dependencies={['feedbackTarget']}
          style={{
            marginBottom: 0,
            flex: 1,
            maxWidth: '50%',
          }}
        >
          {({ getFieldValue, setFieldValue }) => {
            return getFieldValue('feedbackTarget') === 'person' ? (
              <Form.Item name="targetId" style={{ marginBottom: 0 }}>
                <Select
                  allowClear
                  showSearch
                  disabled={getFieldValue('feedbackTarget') !== 'person'}
                  optionFilterProp="label"
                  placeholder="Select a person"
                  onChange={(v) => {
                    const selectedOption = employees?.employees?.find(
                      (emp) => emp?.stsUserId === v,
                    )
                    if (selectedOption) {
                      const {
                        firstName = '',
                        middleName = '',
                        lastName = '',
                      } = selectedOption.employeeDetails || {}
                      const label = `${firstName || ''} ${middleName || ''} ${lastName || ''}`
                      setFieldValue('targetName', label)
                      setSelectedReceiver(v)
                    }
                  }}
                >
                  {sortedEmployees.map((emp) => {
                    const { id, stsUserId, employeeDetails, email } =
                      emp as User
                    const {
                      firstName = '',
                      middleName = '',
                      lastName = '',
                    } = employeeDetails || {}
                    return (
                      <Option
                        key={id}
                        label={`${firstName || ''} ${middleName || ''} ${lastName || ''} - ${email}`}
                        value={stsUserId}
                      >
                        {`${firstName || ''} ${middleName || ''} ${lastName || ''} - ${email}`}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item name="targetId" style={{ marginBottom: 0 }}>
                <Select
                  allowClear
                  showSearch
                  disabled={getFieldValue('feedbackTarget') !== 'team'}
                  optionFilterProp="label"
                  placeholder="Select a team"
                  onChange={(v) => {
                    const selectedOption = teams?.teams?.find(
                      (team) => team?.id === v,
                    )
                    if (selectedOption) {
                      setFieldValue('targetName', selectedOption.name)
                      setSelectedReceiver(v)
                    }
                  }}
                >
                  {teams?.teams?.map((t) => {
                    const { id, name } = t as Team
                    return (
                      <Option key={id} label={name} value={id}>
                        {name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            )
          }}
        </Form.Item>

        <Tooltip
          placement={'rightBottom'}
          title={
            isButtonDisabled
              ? t('validate.commuication.enterRequiredDetails')
              : t('common.alt.send', 'Send')
          }
        >
          <button
            ref={sendRef}
            className="btn d-flex "
            style={{
              padding: 8,
              border: 0,
              borderRadius: '50%',
              background: 'var(--color-primary)',
              opacity: isButtonDisabled ? 0.5 : 1,
            }}
            onClick={() => {
              if (!isButtonDisabled) {
                setFeedbackModal(true)
              }
            }}
          >
            <Icon
              color="white"
              height="18"
              icon="ri:mail-add-line"
              type="button"
              width="18"
              style={{
                cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
              }}
            />
          </button>
        </Tooltip>
      </div>
    </Form>
  )
}

export default FeedFwdCanvasHeader
