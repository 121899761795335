import { Flex } from 'antd'
import { useContext } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CategoryForm } from 'src/components/Category'
import Tooltip from 'src/components/UI/Tooltip'
import DeleteButton from 'src/components/common/DeleteButton'
import { AuthContext } from '../../components/Auth/AuthProvider'
import PageHeader from '../../components/common/PageHeader'
import {
  Category,
  GetCategoriesDocument,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from '../../gql/graphql'
import { usePagination } from '../../hooks/usePagination'

const CategoryList = () => {
  const { t } = useTranslation()

  const { userInfo } = useContext(AuthContext)
  const { currentPage, pageSize, handlePageChange, handleRowsPerPageChange } =
    usePagination()

  const { data, loading } = useGetCategoriesQuery({
    variables: { currentPage, pageSize },
    onError: () => {
      toast.error(t('resources.category.fields.loadingErr'))
    },
  })

  const [deleteCategory, { loading: deleteLoading }] =
    useDeleteCategoryMutation()

  const handleDelete = async (categoryId: string) => {
    try {
      await deleteCategory({
        variables: {
          categoryId,
        },
        refetchQueries: [GetCategoriesDocument],
      })
      toast.success(
        t('notification.delete.success', {
          name: t('resources.category.name', 'Category'),
        }),
      )
    } catch (error) {
      toast.error(t('common.toast.error'))
    }
  }

  const columns: TableColumn<Category>[] = [
    {
      name: t('common.word.title'),
      selector: (row) => row.name,
    },
    {
      name: t('common.word.type'),
      selector: (row) => row.type,
    },
    {
      name: '',
      cell: (row) => {
        return (
          <Flex
            wrap
            gap="small"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CategoryForm record={row} />
            <Tooltip
              message={t('action.delete', 'Delete', {
                name: t('resources.category.name', 'Category'),
              })}
            >
              <DeleteButton
                disabled={deleteLoading}
                modalBody={t(
                  'resources.category.fields.deleteCategory',
                  'Are you sure you want to delete this category?',
                )}
                modalHeader={t('action.delete', 'Delete', {
                  name: t('resources.category.name', 'Category'),
                })}
                onDelete={() => handleDelete(row.id)}
              />
            </Tooltip>

            {/* <IconButton /> */}
          </Flex>
        )
      },
    },
  ]

  return (
    <div className="container-xxl">
      <PageHeader
        headerTitle={t('hr.programForm.form.categories')}
        renderRight={() => (
          <div className="col-auto d-flex w-sm-100">
            <CategoryForm isCreate record={null} />
          </div>
        )}
      />

      <DataTable
        pagination
        paginationServer
        columns={columns}
        data={data?.categories?.categories as Category[]}
        defaultSortFieldId={1}
        paginationTotalRows={data?.categories?.totalItems || 0}
        progressPending={loading || deleteLoading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  )
}

export default CategoryList
