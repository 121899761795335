import get from 'lodash/get'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import {
  Organization,
  OrganizationCategory,
  Role,
  WorkingRole,
  useUpdateUserRoleMutation,
} from 'src/gql/graphql'
import { AuthContext } from '../Auth/AuthProvider'
import CreateCompanyForm from '../IT/CreateCompanyForm'
import { Icon } from '@iconify/react'
import { useTranslation } from 'react-i18next'
import { PowerfulRoles } from 'src/constants/common.constant'

export default function OrganizationSwitcher() {
  const { userInfo, setUserInfo } = useContext(AuthContext)
  const [currentOrganization, setCurrentOrganization] = useState<
    Organization | null | undefined
  >(userInfo?.organization)

  const [currentOrgId, setCurrentOrgId] = useState<string | null>(
    localStorage.getItem('organizationId'),
  )
  const currentRole = get(userInfo, 'employeeDetails.role.name')

  const { t } = useTranslation()

  const [switchProfile, { loading: switchProfileLoading }] =
    useUpdateUserRoleMutation()

  const organizationList = useMemo(() => {
    const userOrgs =
      userInfo?.userOrganizations?.map((userOrg) => userOrg.organization) ?? []
    if (process.env.REACT_APP_ENV === 'prod') {
      return userOrgs.filter(
        (org) => org.category !== OrganizationCategory.Personal,
      )
    }
    return userOrgs
  }, [userInfo?.userOrganizations])

  //Check if the current organization is personal and the user is the owner
  const isCurrentOrgPersonal = useMemo(
    () =>
      currentOrganization?.category === OrganizationCategory.Personal &&
      currentOrganization.ownerId === userInfo?.id,
    [currentOrganization, userInfo],
  )

  //Check if the user has owned 3 organizations
  const isMaximumOrganizations = useMemo(() => {
    const organizationsThatIsOwner =
      userInfo?.userOrganizations?.filter(
        (userOrg) => userOrg.organization.ownerId === userInfo.id,
      ) ?? []
    return (
      organizationsThatIsOwner.length >= 3 ||
      PowerfulRoles.includes(currentRole as Role)
    )
  }, [userInfo])

  useEffect(() => {
    const organisation = get(userInfo, 'organization')
    if (organisation) {
      setCurrentOrgId(organisation.id)
    }
  }, [userInfo])

  useEffect(() => {
    if (!organizationList.length) return

    const localStorageOrg = organizationList.find(
      (org) => org.id === currentOrgId,
    )
    const defaultOrg = userInfo?.organization

    if (!localStorageOrg && !defaultOrg) {
      console.error(
        'Failed to load organizations: No active or stored organization found.',
      )
    }

    setCurrentOrganization(localStorageOrg ?? defaultOrg)
  }, [organizationList, currentOrgId])

  const handleOrganizationChange = (organizationId: string) => {
    localStorage.setItem('organizationId', organizationId)
    setCurrentOrgId(organizationId)

    const updatedOrganization = organizationList.find(
      (org) => org.id === organizationId,
    )

    let newUserRoleId = ''

    setUserInfo((prev) => {
      if (!prev) return prev

      const updatedRoles = prev.permissions?.find(
        (permission) => permission?.organizationId === organizationId,
      )?.workingRoles as WorkingRole[]

      if (
        updatedRoles.every((role) => role.id !== prev.employeeDetails?.role?.id)
      ) {
        newUserRoleId = updatedRoles[0].id
      }

      return {
        ...prev,
        organization: updatedOrganization,
        roles: updatedRoles,
      }
    })

    if (newUserRoleId && userInfo?.employeeDetails?.employeeId) {
      switchProfile({
        variables: {
          employeeId: userInfo.employeeDetails.employeeId,
          roleId: newUserRoleId,
        },
        onCompleted: () => {
          window.location.reload()
        },
      })
    }
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="nav-link d-flex align-items-center"
        id="dropdown-language"
        variant="link"
        style={{
          color: isCurrentOrgPersonal ? 'var(--color-primary)' : 'white',
          background: isCurrentOrgPersonal ? 'white' : 'transparent',
        }}
      >
        <div
          className={`me-2 border ${isCurrentOrgPersonal ? 'border-primary' : ''} rounded-circle text-center`}
          style={{
            width: 20,
            height: 20,
            fontSize: 13,
          }}
        >
          {currentOrganization?.name[0]}
        </div>

        <span
          className="fs-6 d-inline-block"
          style={{
            color: isCurrentOrgPersonal ? 'var(--color-primary)' : 'white',
          }}
        >
          {currentOrganization?.name}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="p-1">
        {organizationList.map((organization) => {
          const { id, name } = organization

          return (
            <Dropdown.Item
              key={id}
              className="rounded"
              style={{
                ...(id === currentOrgId && {
                  background: 'var(--primary-color)',
                  color: 'var(--color-on-primary)',
                }),
              }}
              onClick={() => id && handleOrganizationChange(id)}
            >
              {name}
            </Dropdown.Item>
          )
        })}
        {
          <div className="my-1">
            <CreateCompanyForm
              btnProps={{
                style: {
                  width: '100%',
                },
                label: (
                  <span className="d-flex gap-1">
                    <Icon height="20" icon="ix:plus" width="20" />
                    {t('common.word.addWorkspace', 'Add a Workspace')}
                  </span>
                ),
                disabled: isMaximumOrganizations,
              }}
            />
          </div>
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}
