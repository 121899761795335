import { Tour, TourProps } from 'antd'
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'
import { CaiPopupContext } from '../../context/CaiPopupContext'

interface CaiTourProps {
  open: boolean
  setOpen: (open: boolean) => void
  didUpdateMsg: boolean
  fullScreen: boolean
  handleFullSize: (value: boolean | MouseEvent) => void
  newChat: (selectedFlow?: string) => Promise<void>
  audioEnabled: boolean
  setAudioEnabled: Dispatch<SetStateAction<boolean>>
  onSessionEnd: () => void
}

async function mp3ToBlob(mp3Url: string) {
  const response = await fetch(mp3Url)
  const arrayBuffer = await response.arrayBuffer()
  const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' })
  return blob
}

function getMp3Links(lang: string) {
  return [
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_introduction.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_language.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_profileSelection.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_noti.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_caiMessage.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_newChat.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_lib.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_feedFwd.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_reduceWindow.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_drag.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_caiIcon.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/onboarding-mp3/${lang}/onboarding_personalhub.mp3`,
  ]
}

const CaiTour: FC<CaiTourProps> = ({
  open,
  setOpen,
  didUpdateMsg = false,
  newChat,
  fullScreen,
  handleFullSize,
  audioEnabled,
  setAudioEnabled,
  onSessionEnd,
}) => {
  const { t, i18n } = useTranslation()

  const [step, setStep] = useState(0)
  const [overlayTour, setOverlayTour] = useState(false)
  const tourRef = useRef<HTMLDivElement | null>(null)
  const {
    notificationRef,
    librarianRef,
    feedFwdRef,
    agentChatRef,
    sizeBtnRef,
    draggingBtnRef,
    newChatRef,
    languageSelectionRef,
    profileSelectionRef,
    aiChatIconRef,
    tourAudio,
    setTourAudio,
    hubMenuItem,
  } = useContext(CaiPopupContext)
  const isChangeSize = Boolean(sessionStorage.getItem('isChangeSize'))

  let language
  if (
    i18n.language !== 'en' &&
    i18n.language !== 'fr' &&
    i18n.language !== 'es'
  ) {
    language = 'en'
  } else {
    language = i18n.language
  }

  const mp3Links = getMp3Links(language)

  const [isUpdateMsg, setIsUpdateMsg] = useState(false)

  useEffect(() => {
    if (didUpdateMsg) setIsUpdateMsg(true)
  })

  const playAudio = useCallback(
    (mp3Url: string) => {
      if (tourAudio) {
        tourAudio.pause()
        setTourAudio(null)
      }

      mp3ToBlob(mp3Url).then((blob) => {
        const objectURL = URL.createObjectURL(blob)
        const tempAudio = new Audio(objectURL)

        setTourAudio(tempAudio)
        if (audioEnabled) tempAudio.play()
      })
    },
    [audioEnabled, tourAudio],
  )

  const steps: TourProps['steps'] = [
    {
      title: t('caiPopup.tour.step1.title', 'Before you begin'),
      description: t(
        'caiPopup.tour.step1.description',
        'Let me give you a 1 minute tour of your personal AI Coach Cai.',
      ),
      placement: 'center',
      target: () => tourRef.current as HTMLElement,
    },
    {
      title: t('caiPopup.tour.step2.title', 'Language selection'),
      description: t(
        'caiPopup.tour.step2.description',
        'You can select your preferred language here.',
      ),
      placement: 'rightTop',
      target: () => languageSelectionRef?.current as HTMLElement,
    },
    {
      title: t('caiPopup.tour.step3.title', 'Profile selection'),
      description: t(
        'caiPopup.tour.step3.description',
        'You can change your view here to access other roles you have been assigned.',
      ),
      placement: 'leftBottom',
      target: () => profileSelectionRef?.current as HTMLElement,
    },
    {
      title: t('caiPopup.tour.step4.title', 'Notification'),
      description: t(
        'caiPopup.tour.step4.description',
        'Click to see new Tasks and Growth Opportunities from various sources as well as insights and observations from your colleagues.',
      ),
      placement: 'rightTop',
      target: () => notificationRef?.current as HTMLElement,
    },
    {
      title: t('caiPopup.tour.step5.title', 'New Chat'),
      description: t(
        'caiPopup.tour.step5.description',
        `Now, let's try chatting to Cai. Select some bubbles or type a message.`,
      ),
      placement: 'center',
      target: () => agentChatRef?.current as HTMLElement,
      nextButtonProps: {
        disabled: !isUpdateMsg,
      } as any,
    },
    {
      title: t('caiPopup.tour.step6.title', 'New Chat'),
      description: t(
        'caiPopup.tour.step6.description',
        'Click New Chat whenever you would like to change topic or begin a new conversation.',
      ),
      placement: 'bottom',
      target: () => newChatRef?.current as HTMLElement,
    },
    {
      title: t('caiPopup.tour.step7.title', 'Librarian'),
      description: t(
        'caiPopup.tour.step7.description',
        'This AI agent is your guide to access and educate yourself on Company documents, processes and policies.',
      ),
      placement: 'bottom',
      target: () => librarianRef?.current as HTMLElement,
    },
    {
      title: t('caiPopup.tour.step8.title', 'FeedForward'),
      description: t(
        'caiPopup.tour.step8.description',
        'FeedForward helps you structure and send your insights and observations to people and teams. Receive coaching from Cai and then send it anonymously coach-to-coach.',
      ),
      placement: 'leftBottom' as const,
      target: () => feedFwdRef?.current as HTMLElement,
    },
    {
      title: t('caiPopup.tour.step9.title', 'Reduce window'),
      description: t(
        'caiPopup.tour.step9.description',
        'Click here to reduce the size of the Cai chat window.',
      ),
      placement: 'leftBottom',
      target: () => sizeBtnRef?.current as HTMLElement,
      nextButtonProps: {
        disabled: true,
      } as any,
    },
    //Small CAI steps
    {
      title: t('caiPopup.tour.step10.title', 'Dragging button'),
      description: t(
        'caiPopup.tour.step10.description',
        'Click and drag this button to move the Cai chat window.',
      ),
      placement: 'left',
      target: () => draggingBtnRef?.current as HTMLElement,
      onPrev: () => {
        sizeBtnRef?.current?.click()
        sessionStorage.setItem('isChangeSize', 'true')
      },
    },
    {
      title: t('caiPopup.tour.step11.title', 'AI assistant Icon'),
      description: t(
        'caiPopup.tour.step11.description',
        'You can open and close the Cai Chat by clicking this icon',
      ),
      placement: 'right',
      target: () => aiChatIconRef?.current as HTMLElement,
    },
    {
      title: t('caiPopup.tour.step12.title', 'Personal Hub'),
      description: t(
        'caiPopup.tour.step12.description',
        'This is where all your personal information is stored, it is only accessible by you.',
      ),
      placement: 'right',
      target: () => hubMenuItem?.current as HTMLElement,
    },
  ]

  useEffect(() => {
    if (step === 0 && !isChangeSize && !fullScreen) handleFullSize(true)
  }, [step])

  useEffect(() => {
    if (!open) return

    if (fullScreen) {
      //Default case
      if (!isChangeSize) {
        setOverlayTour(true)
        playAudio(mp3Links[0])
        return
      }

      //Handle case switching from step 9 to step 8
      setStep(8)
      playAudio(mp3Links[8])
      return
    }

    //Handle case switching from step 8 to step 9
    if (isChangeSize) {
      playAudio(mp3Links[9])
      setStep(9)
      return
    }
  }, [open])

  window.addEventListener('beforeunload', function (e) {
    sessionStorage.removeItem('isChangeSize')
  })

  const handleClose = () => {
    setOpen(false)
    setOverlayTour(false)
    onSessionEnd()
    if (step === steps.length - 1) sessionStorage.removeItem('isChangeSize')
  }

  return (
    <Tour
      current={step}
      open={open}
      steps={steps}
      disabledInteraction={
        step === 1 || step === 2 || step === 9 || step === 10 //disabled interaction for language selection, profile selection, reduce window, AI assistant Icon
      }
      gap={{
        offset: 1,
      }}
      indicatorsRender={() => (
        <>
          <i
            style={{ color: 'var(--icon-color)', cursor: 'pointer' }}
            className={`${
              audioEnabled ? 'icofont-volume-down' : 'icofont-volume-mute'
            } fs-5`}
            onClick={() => {
              setAudioEnabled((prev) => {
                return !prev
              })
              if (audioEnabled) {
                tourAudio?.pause()
              } else {
                tourAudio?.play()
              }
            }}
          ></i>
        </>
      )}
      onClose={handleClose}
      onFinish={handleClose}
      onChange={(e) => {
        setTimeout(() => {
          setStep(e)
        }, 100)

        switch (e) {
          case 3:
            setOverlayTour(false)
            break
          case 4:
            newChat(CAI_CHAT_FLOWS.DEFAULT)
            setOverlayTour(false)
            break
          case 5:
            break
          case 6:
            setOverlayTour(false)
            newChat(CAI_CHAT_FLOWS.LIBRARIAN)
            break
          case 7:
            setOverlayTour(false)
            newChat(CAI_CHAT_FLOWS.FEEDBACK)
            break
          case 8:
            sessionStorage.setItem('isChangeSize', 'true')
            break
          case 9:
            setOverlayTour(false)
            break
          case 10:
            setOverlayTour(false)
            break
        }

        playAudio(mp3Links[e])
      }}
    />
  )
}

export default CaiTour
