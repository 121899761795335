import { Icon } from '@iconify/react'
import { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import { Role } from 'src/gql/graphql'
import myCoachLogo from '../../assets/images/my-coach-logo.svg'
import { useClickOutside } from '../../utils/useClickOutside'
import { AuthContext } from '../Auth/AuthProvider'
import { Divider } from '../UI/Divider'
import LanguageSwitcher from '../common/LanguageSwitcher'
import OrganizationSwitcher from '../common/OrganizationSwitcher'
import { MENU } from './menu'
import { Button } from 'react-bootstrap'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'

function Sidebar(props) {
  const { activeKey } = props

  const { t } = useTranslation()

  const { hubMenuItem, setFlow, setShow, setLibrarianMsg } =
    useContext(CaiPopupContext)
  const { userInfo } = useContext(AuthContext)
  const roleName = userInfo?.employeeDetails?.role?.name

  const [menuData, setMenuData] = useState([])

  const [openingItem, setOpeningItem] = useState(
    localStorage.getItem('openingItem'),
  )

  const ref = useClickOutside(() => {
    let side = document.getElementById('mainSideMenu')
    if (side && side.classList.contains('open')) {
      side.classList.remove('open')
    }
  })

  const handleItemClick = (item) => {
    localStorage.setItem('openingItem', item === openingItem ? undefined : item)
    setOpeningItem(item === openingItem ? undefined : item)
  }

  useEffect(() => {
    if (roleName) {
      setMenuData(MENU.filter((e) => e.identifier === roleName))
    }
  }, [roleName])

  useEffect(() => {
    window.document.children[0].setAttribute('data-theme', 'light')
  }, [])

  return (
    <div
      ref={ref}
      className="sidebar px-3 py-3 "
      id="mainSideMenu"
      style={{ maxHeight: '100vh' }}
    >
      <div className="d-flex flex-column h-100">
        <img
          alt="my-coach-logo"
          src={userInfo?.organization?.logo || myCoachLogo}
        />

        <div className="d-flex flex-column mt-2">
          <LanguageSwitcher />

          {<OrganizationSwitcher />}
        </div>

        <Divider style={{ marginTop: 10 }} />

        <ul className="menu-list flex-grow-1 overflow-y-auto overflow-x-hidden pt-4 ">
          {menuData.map((d) => (
            <li key={d.name}>
              <ul className="ps-0 mt-2" style={{ listStyleType: 'none' }}>
                {d.children.map((data) => {
                  // render group
                  if (data.children) {
                    return (
                      <div key={data.name}>
                        <li
                          className="d-flex align-items-center"
                          style={{
                            paddingLeft: 12,
                            height: 52,
                            cursor: 'pointer',
                            ...(!!(activeKey === '/' + data.routerLink[0]) && {
                              backgroundColor: 'var(--color-primary-80)',
                              borderRadius: 8,
                            }),
                          }}
                          onClick={() => handleItemClick(data.name)}
                        >
                          <div className="d-flex gap-3">
                            <img
                              alt={data.name}
                              src={data.iconClass}
                              style={{ maxWidth: 22 }}
                            />

                            <div
                              className="fs-6 position-relative"
                              style={{ color: 'var(--color-on-primary)' }}
                            >
                              <div className="overflow-hidden">
                                {t(data.name)}
                              </div>

                              <Icon
                                className="position-absolute"
                                height="22"
                                style={{ right: -25, top: 3 }}
                                width="22"
                                icon={`material-symbols-light:keyboard-arrow-${
                                  openingItem === data.name ? 'up' : 'down'
                                }`}
                              />
                            </div>
                          </div>
                        </li>

                        {openingItem === data.name &&
                          data.children.map((child) => (
                            <Link
                              key={child.name}
                              to={`${process.env.PUBLIC_URL}/${child.routerLink[0]}`}
                            >
                              <li
                                className="d-flex align-items-center"
                                style={{
                                  paddingLeft: 30,
                                  height: 52,
                                  ...(!!(
                                    activeKey ===
                                    '/' + child.routerLink[0]
                                  ) && {
                                    backgroundColor: 'var(--color-primary-80)',
                                    borderRadius: 8,
                                  }),
                                }}
                              >
                                <div className="d-flex gap-3">
                                  <img alt={child.name} src={child.iconClass} />

                                  <span
                                    className="fs-6"
                                    style={{ color: 'var(--color-on-primary)' }}
                                  >
                                    {t(child.name)}
                                  </span>
                                </div>
                              </li>
                            </Link>
                          ))}
                      </div>
                    )
                  }

                  // render item
                  return (
                    <Link
                      key={data.name}
                      to={`${process.env.PUBLIC_URL}/${data.routerLink[0]}`}
                    >
                      <li
                        className="d-flex align-items-center"
                        style={{
                          paddingLeft: 12,
                          height: 52,
                          ...(!!(activeKey === '/' + data.routerLink[0]) && {
                            backgroundColor: 'var(--color-primary-80)',
                            borderRadius: 8,
                          }),
                        }}
                      >
                        <div className="d-flex gap-3">
                          <img alt={data.name} src={data.iconClass} />

                          <span
                            className="fs-6"
                            style={{ color: 'var(--color-on-primary)' }}
                          >
                            {t(data.name)}
                          </span>
                        </div>
                      </li>
                    </Link>
                  )
                })}
              </ul>
            </li>
          ))}

          {/* for employee */}
          {roleName === Role.Employee && (
            <li className="mt-3">
              <span style={{ color: 'var(--color-on-primary-40)' }}>
                Personal
              </span>

              <ul className="ps-0 mt-2" style={{ listStyleType: 'none' }}>
                <Link to="/personal-hub">
                  <li
                    ref={hubMenuItem}
                    className="d-flex align-items-center"
                    style={{
                      paddingLeft: 12,
                      height: 52,
                      ...(!!(activeKey === '/' + 'personal-hub') && {
                        backgroundColor: 'var(--color-primary-80)',
                        borderRadius: 8,
                      }),
                    }}
                  >
                    <div className="d-flex gap-3">
                      <Icon
                        height="24"
                        icon="material-symbols-light:grid-view-outline-rounded"
                        style={{ color: 'white' }}
                        width="24"
                      />

                      <span
                        className="fs-6"
                        style={{ color: 'var(--color-on-primary)' }}
                      >
                        Hub
                      </span>
                    </div>
                  </li>
                </Link>
                <Link to="/personal-settings">
                  <li
                    className="d-flex align-items-center"
                    style={{
                      paddingLeft: 12,
                      height: 52,
                      ...(!!(activeKey === '/' + 'personal-settings') && {
                        backgroundColor: 'var(--color-primary-80)',
                        borderRadius: 8,
                      }),
                    }}
                  >
                    <div className="d-flex gap-3">
                      <Icon
                        height="24"
                        icon="carbon:settings-edit"
                        style={{ color: 'white' }}
                        width="24"
                      />
                      <span
                        className="fs-6"
                        style={{ color: 'var(--color-on-primary)' }}
                      >
                        {t('common.word.settings')}
                      </span>
                    </div>
                  </li>
                </Link>
              </ul>
            </li>
          )}
        </ul>

        <Divider style={{ marginBottom: 20 }} />
        <div className="d-flex flex-column">
          <Button
            className="d-flex align-items-cente px-3 py-2"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShow(true)
              setFlow(CAI_CHAT_FLOWS.LIBRARIAN)
              setLibrarianMsg(
                <div>
                  {t('support.askQuestion')}&nbsp;
                  {t('support.contactText')}&nbsp;
                  <a
                    href="mailto:support@cognentai.com"
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    support@cognentai.com
                  </a>
                  &nbsp;
                  {t('support.furtherAssistance')}
                </div>,
              )
            }}
          >
            <div className="d-flex gap-2">
              <Icon
                height="24"
                icon="material-symbols:help-outline"
                width="24"
              />
              <span style={{ color: 'var(--color-on-primary)' }}>
                {t('common.word.help')}
              </span>
            </div>
          </Button>

          <i
            className="mt-2"
            style={{ fontSize: 10, color: 'var(--color-on-primary-40)' }}
          >
            {`version: ${process.env.REACT_APP_VERSION}`}
          </i>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
