import { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CaiPopupContext } from '../../context/CaiPopupContext'
import { AuthContext } from 'src/components/Auth/AuthProvider'
import { useEffect } from 'react'

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation()
  const { languageSelectionRef } = useContext(CaiPopupContext)
  const { userInfo } = useContext(AuthContext)
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('preferredLanguage', lng)
  }

  useEffect(() => {
    const preferredLanguage = localStorage.getItem('preferredLanguage')

    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage)
    } else if (userInfo?.organization?.defaultLanguage) {
      i18n.changeLanguage(userInfo.organization.defaultLanguage)
    }
  }, [userInfo?.organization?.defaultLanguage, i18n])

  return (
    <Dropdown>
      <Dropdown.Toggle
        ref={languageSelectionRef}
        className="nav-link d-flex align-items-center"
        id="dropdown-language"
        style={{ color: 'var(--color-on-primary)' }}
        variant="link"
      >
        <i className="icofont-globe fs-5 me-2"></i>
        <span className="fs-6 d-inline-block">
          {t('common.word.language')}: {i18n.language.toUpperCase()}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage('en')}>
          English
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage('fr')}>
          Français
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage('es')}>
          Español
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageSwitcher
