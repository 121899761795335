import isBoolean from 'lodash/isBoolean'
import { Fragment, memo, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'
import { CaiChatContext } from '../../context/CaiChatContext'
import { CaiPopupContext } from '../../context/CaiPopupContext'
import { NotificationContext } from '../../context/NotificationContext'
import CaiChat from '../../screens/App/CaiChat'
import { AuthContext } from '../Auth/AuthProvider'
import CAIIcon from '../CaiIcon'
import Tooltip from '../UI/Tooltip'

type Props = {
  showInput?: boolean
  isTopBar?: boolean
  caiStyle?: React.CSSProperties
}

function CaiPopup(props: Props) {
  const {
    showInput = true,
    caiStyle = {
      width: 50,
      height: 50,
    },
    isTopBar = false,
  } = props

  const { t } = useTranslation()

  const { userInfo } = useContext(AuthContext)
  const { sendMessage } = useContext(CaiChatContext)
  const { show, setShow, setFlow, aiChatIconRef } = useContext(CaiPopupContext)
  const { setSessionId } = useContext(NotificationContext)

  const [fullSize, setFullSize] = useState(true)
  const caiChatOpenCountRef = useRef(0)

  const handleShow = () => {
    setShow((state) => !state)
    caiChatOpenCountRef.current += 1
  }

  const handleFullSize = (value: boolean | MouseEvent) => {
    setFullSize((state) => (isBoolean(value) ? value : !state))
  }

  const handleEscPress = () => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShow(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }

  useEffect(() => {
    if (userInfo?.isFirstLogin) {
      handleShow()
    }
  }, [userInfo?.isFirstLogin])

  useEffect(() => {
    if (!show) {
      setFlow(CAI_CHAT_FLOWS.DEFAULT)
      setSessionId('')
    }
  }, [show])

  useEffect(handleEscPress, [])

  return (
    <>
      {!showInput && (
        <Fragment>
          <div className="d-flex flex-row h-100">
            <div className="d-flex justify-content-center">
              <Tooltip
                message={t('caiPopup.tooltip')}
                overlayTriggerProps={{
                  placement: 'top',
                }}
              >
                <button
                  ref={aiChatIconRef}
                  className={`btn ${isTopBar ? 'me-1' : 'p-2 mb-3'} outline-none border-0`}
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '100px 100px 100px 0',
                    boxShadow: '0 0 40px rgba(242, 229, 193, 1)',
                  }}
                  onClick={handleShow}
                >
                  <CAIIcon isCai={show} style={{ ...caiStyle }} />
                </button>
              </Tooltip>
            </div>
          </div>
        </Fragment>
      )}

      <CaiChat
        fullScreen={fullSize}
        handleFullSize={handleFullSize}
        handleShow={handleShow}
        showInput={showInput}
      />
    </>
  )
}

export default memo(CaiPopup)
