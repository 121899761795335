export const formatTime = (isoString: string) => {
  const date = new Date(isoString)
  return date.toLocaleString('en-US', { timeZoneName: 'short' })
}

export const getFirstCaiIntro = (userName: string) => {
  return `Hi ${userName}, nice to meet you! I am Cai, your personal AI Coach. I'm always available to provide you with assistance and information related to the company or the MyCoach platform. How are you going today?`
}

export const getSubsequentCaiIntro = (userName: string, langCode = 'en') => {
  const phrases = {
    en: [
      `Hello ${userName}! Ready to assist….`,
      `Standing by if you need anything, ${userName}!`,
      `Greetings ${userName}! How can I help?`,
      `Welcome back, ${userName}! Need anything?`,
      `Hi there ${userName}! Got a task for me?`,
      `Good to see you, ${userName}! Questions?`,
      `Hello ${userName}! I'm here to help.`,
      `Hi ${userName}! What's on the agenda?`,
      `Greetings ${userName}! How may I assist?`,
      `Hello ${userName}! Ready when you are.`,
      `Hi there ${userName}! What's up?`,
      `Greetings ${userName}! What's our focus?`,
      `Hello ${userName}, can I support in any way?`,
      `Welcome back ${userName}! What's next?`,
      `Welcome back, ${userName}!`,
      `Good to see you, ${userName}!`,
      `Hi ${userName}, I'm here! Need help?`,
    ],
    fr: [
      `Bonjour ${userName}! Prêt à assister….`,
      `Je suis là si tu as besoin de quelque chose, ${userName}!`,
      `Salutations ${userName}! Comment puis-je aider?`,
      `Bon retour, ${userName}! Besoin de quelque chose?`,
      `Salut ${userName}! Une tâche pour moi?`,
      `Content de te revoir, ${userName}! Des questions?`,
      `Bonjour ${userName}! Je suis là pour aider.`,
      `Salut ${userName}! Quel est le programme?`,
      `Salutations ${userName}! Comment puis-je vous assister?`,
      `Bonjour ${userName}! Prêt quand tu l'es.`,
      `Salut ${userName}! Quoi de neuf?`,
      `Salutations ${userName}! Quel est notre objectif?`,
      `Bonjour ${userName}, puis-je aider de quelque manière que ce soit?`,
      `Bon retour ${userName}! Quelle est la suite?`,
      `Bon retour, ${userName}!`,
      `Content de te revoir, ${userName}!`,
      `Salut ${userName}, je suis là! Besoin d'aide?`,
    ],
    es: [
      `¡Hola ${userName}! Listo para ayudar...`,
      `¡Estoy aquí si necesitas algo, ${userName}!`,
      `¡Saludos ${userName}! ¿Cómo puedo ayudar?`,
      `¡Bienvenido de nuevo, ${userName}! ¿Necesitas algo?`,
      `¡Hola ${userName}! ¿Tienes alguna tarea para mí?`,
      `¡Me alegro de verte, ${userName}! ¿Preguntas?`,
      `¡Hola ${userName}! Estoy aquí para ayudar.`,
      `¡Hola ${userName}! ¿Cuál es la agenda?`,
      `¡Saludos ${userName}! ¿En qué puedo ayudarte?`,
      `¡Hola ${userName}! Listo cuando tú lo estés.`,
      `¡Hola ${userName}! ¿Qué hay de nuevo?`,
      `¡Saludos ${userName}! ¿Cuál es nuestro enfoque?`,
      `Hola ${userName}, ¿puedo ayudarte en algo?`,
      `¡Bienvenido de nuevo ${userName}! ¿Qué sigue?`,
      `¡Bienvenido de nuevo, ${userName}!`,
      `¡Me alegro de verte, ${userName}!`,
      `¡Hola ${userName}, estoy aquí! ¿Necesitas ayuda?`,
    ],
  }

  const languageMap = {
    fr: 'fr',
    es: 'es',
    en: 'en',
  }

  const selectedLanguage =
    Object.keys(languageMap).find((lang) => langCode.includes(lang)) || 'en'
  const selectedPhrases = phrases[selectedLanguage as keyof typeof phrases]

  return selectedPhrases[Math.floor(Math.random() * selectedPhrases.length)]
}
