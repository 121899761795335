import React, { ReactElement } from 'react'
import styled from 'styled-components'

const TopBarContainer = styled.div`
  background: linear-gradient(
    90deg,
    #6b46c1 0%,
    #8e5ce6 100%
  ); /* Gradient for depth */
  padding-left: 5px;
  // height: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 8px; /* Softer edges */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow for floating effect */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Subtle lift on hover */
    background: linear-gradient(
      90deg,
      #5a378f 0%,
      #7d4ad4 100%
    ); /* Darker gradient on hover */
  }
`

const PopupWrapper = styled.div`
  cursor: pointer;
  height: 100% !important;
  transition: transform 0.2s ease;
`

export const CaiBar = React.memo(({ children }: { children: ReactElement }) => {
  return (
    <TopBarContainer>
      <PopupWrapper>{children}</PopupWrapper>
    </TopBarContainer>
  )
})
