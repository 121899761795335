import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Spinner, Stack } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { StrictOmit } from 'ts-essentials'
import {
  Permissions,
  CaiInstance,
  Organization,
  useGetCaiInstanceQuery,
  useGetMeQuery,
  User,
  WorkingRole,
} from '../../gql/graphql'
import { isExpired } from '../../utils/auth'
import { checkTokenExpirationAndLogout, transformAuthData } from './utils'
import { Maybe } from 'graphql/jsutils/Maybe'

export type UserInfo = StrictOmit<User, 'roles'> & {
  caiInstance?: CaiInstance | null
  organization?: Organization
  permissions?: Maybe<Permissions>[] | null
  roles?: Maybe<WorkingRole>[] | null
}

type States = {
  auth?: { token: string }
  userInfo?: UserInfo
}

type Actions = {
  login: (token: string, redirectUrl?: string) => void
  logout: () => void
  setUserInfo: Dispatch<SetStateAction<UserInfo | undefined>>
}

export type AuthContextType = States & Actions

export const AuthContext = createContext<AuthContextType>({
  auth: undefined,
  userInfo: undefined,

  login: () => {},
  logout: () => {},
  setUserInfo: () => {},
})

export const AuthProvider = ({ children }: PropsWithChildren) => {
  // utils
  const { t } = useTranslation()
  const history = useHistory()
  const accessToken = localStorage.getItem('accessToken')

  // states
  const [auth, setAuth] = useState<States['auth']>(
    accessToken ? { token: accessToken } : undefined,
  )
  const [userInfo, setUserInfo] = useState<States['userInfo']>()

  // apis
  const { data, loading } = useGetMeQuery({
    skip: !auth?.token,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      logout()
      history.push('/sign-in')
      toast.error(
        t(
          'auth.authProvider.toast.error',
          'Session has expired, please log back in.',
        ),
      )
      console.error(error)
    },
  })

  const userId = data?.me?.id ?? ''

  useGetCaiInstanceQuery({
    variables: { userId },
    skip: !userId,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (userInfo) {
        setUserInfo({ ...userInfo, caiInstance: data.caiInstance })
      }
    },
    onError: (error) => {
      toast.error(
        t(
          'common.toast.error',
          'Something went wrong. Please try again later.',
        ),
      )
      console.error(error)
    },
  })

  // funcs
  const login = (token: string, redirectUrl = '/') => {
    if (!token || isExpired(token)) return
    setAuth({ token })
    localStorage.setItem('accessToken', token)
    history.push(redirectUrl)
  }

  const logout = () => {
    setAuth(undefined)
    setUserInfo(undefined)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('organizationId')
  }

  if (accessToken) {
    checkTokenExpirationAndLogout(accessToken, logout)
  }

  useEffect(() => {
    if (!data?.me) return

    const newData = transformAuthData(data.me as User)
    setUserInfo(newData)
  }, [JSON.stringify(data?.me)])

  if (loading) {
    return (
      <Stack style={{ height: '100vh' }}>
        <Spinner style={{ margin: 'auto' }} variant="primary" />
      </Stack>
    )
  }

  const value: AuthContextType = {
    auth,
    userInfo,
    login,
    logout,
    setUserInfo,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
