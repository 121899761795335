import { Tour, TourProps } from 'antd'
import { t } from 'i18next'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { CommunicationContext } from 'src/context/CommunicationContext'
import './FeedFwdTour.scss'
import plusIcon from 'src/assets/images/plusIcon.gif'
import wankGif from 'src/assets/images/wank.gif'
import sendGif from 'src/assets/images/sendInstruction.gif'

interface CaiTourProps {
  open: boolean
  setOpen: (open: boolean) => void
  initialFeedback?: string
}

const tourImgs = [
  `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/mobile-img/communication.tour.step1.description.png`,
  `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/mobile-img/communication.tour.step2.description.png`,
  ``,
  ``,
  ``,
  `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/mobile-img/communication.tour.step5.description.png`,
]

const mobileSteps: TourProps['steps'] = [
  {
    title: t('communication.tour.step1.title'),
    description: t('communication.tour.step1.description'),
    placement: 'center',
    cover: <img alt={t('communication.tour.step1.title')} src={tourImgs[0]} />,
  },
  {
    title: t('communication.tour.step2.title'),
    description: t('communication.tour.step2.description'),
    placement: 'center',
    cover: <img alt={t('communication.tour.step2.title')} src={tourImgs[1]} />,
  },
  {
    title: t('communication.tour.step3.title'),
    description: t('communication.tour.step3.description'),
    placement: 'center',

    cover: (
      <img
        alt={t('communication.tour.step3.title')}
        src={wankGif}
        style={{
          maxWidth: '200px',
          maxHeight: '100px',
        }}
      />
    ),
  },
  {
    title: t('communication.tour.step4.title'),
    description: t('communication.tour.step4.description'),
    placement: 'center',
    cover: (
      <img
        alt={t('communication.tour.step4.title')}
        src={sendGif}
        style={{
          maxWidth: '200px',
          maxHeight: '100px',
        }}
      />
    ),
  },

  {
    title: t('communication.tour.step6.title'),
    description: t('communication.tour.step6.description'),
    placement: 'center',
    cover: <img alt={t('communication.tour.step6.title')} src={tourImgs[5]} />,
  },
  {
    title: t('communication.tour.step7.title'),
    description: t('communication.tour.step7.description'),
    placement: 'center',
    cover: (
      <img
        alt="Action buttons"
        src={plusIcon}
        style={{
          maxWidth: '200px',
          maxHeight: '100px',
        }}
      />
    ),
  },
]

async function mp3ToBlob(mp3Url: string) {
  const response = await fetch(mp3Url)
  const arrayBuffer = await response.arrayBuffer()
  const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' })
  return blob
}

function getMp3Links(lang: string) {
  return [
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/${lang}/communication.tour.step1.description.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/${lang}/communication.tour.step2.description.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/${lang}/communication.tour.step3.description.mp3`,
    ``,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/${lang}/communication.tour.step5.description.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/${lang}/communication.tour.step6.description.mp3`,
    `${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/${lang}/communication.tour.step7.description.mp3`,
  ]
}

const FeedFwdTour: FC<CaiTourProps> = ({
  open,
  setOpen,
  initialFeedback = '',
}) => {
  const { t, i18n } = useTranslation()

  const [step, setStep] = useState(0)
  const [overlayTour, setOverlayTour] = useState(false)

  const {
    canvasRef,
    wankRef,
    sendRef,
    interactiveRef,
    tourAudio,
    setTourAudio,
    setFeedbackModal,
    selectedReceiver,
    actionRef,
    reviewRef,
  } = useContext(CommunicationContext)

  const language = i18n.language || 'en'
  const mp3Links = getMp3Links(language)

  const [audioEnabled, setAudioEnabled] = useState(true)
  const [offset, setOffset] = useState<[number, number]>([1, 1])

  const playAudio = useCallback(
    (mp3Url: string) => {
      if (tourAudio) {
        tourAudio.pause()
        setTourAudio(null)
      }

      mp3ToBlob(mp3Url).then((blob) => {
        const objectURL = URL.createObjectURL(blob)
        const tempAudio = new Audio(objectURL)

        setTourAudio(tempAudio)
        if (audioEnabled) tempAudio.play()
      })
    },
    [audioEnabled, tourAudio],
  )

  const steps: TourProps['steps'] = [
    {
      title: t('communication.tour.step1.title'),
      description: t('communication.tour.step1.description'),
      placement: 'bottom',
      target: () => interactiveRef.current as HTMLElement,
    },
    {
      title: t('communication.tour.step2.title'),
      description: t('communication.tour.step2.description'),
      placement: 'top',
      target: () => canvasRef.current as HTMLElement,
    },
    {
      title: t('communication.tour.step3.title'),
      description: t('communication.tour.step3.description'),
      placement: 'leftBottom',
      target: () => wankRef.current as HTMLElement,
      cover: (
        <img
          alt="Description of GIF"
          src={wankGif}
          style={{
            maxWidth: '200px',
            maxHeight: '100px',
          }}
        />
      ),
    },
    {
      title: t('communication.tour.step4.title'),
      description: t('communication.tour.step4.description'),
      placement: 'top',
      target: () => canvasRef.current as HTMLElement,
      // nextButtonProps: {
      //   disabled: initialFeedback === '' || selectedReceiver === '',
      // } as any,
    },
    {
      title: t('communication.tour.step5.title'),
      description: t('communication.tour.step5.description'),
      placement: 'leftBottom',
      target: () => sendRef.current as HTMLElement,
    },

    {
      title: t('communication.tour.step6.title'),
      description: t('communication.tour.step6.description'),
      placement: 'top',
      target: () => reviewRef as HTMLElement,
    },
    {
      title: t('communication.tour.step7.title'),
      description: t('communication.tour.step7.description'),
      placement: 'right',
      target: () => actionRef.current as HTMLElement,
      cover: (
        <img
          alt="Description of GIF"
          src={`${process.env.REACT_APP_ASSETS_ENDPOINT}/communication-tour/plusIcon.gif`}
          style={{
            maxWidth: '200px',
            maxHeight: '100px',
          }}
        />
      ),
    },
  ]

  useEffect(() => {
    if (open) {
      setStep(0)
      if (!isMobile) playAudio(mp3Links[0])
    }
  }, [open])

  const handleClose = () => {
    setOpen(false)
    setOverlayTour(false)
    setFeedbackModal(false)
    setOffset([1, 1])
  }

  return (
    <>
      {overlayTour && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.8)',
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      )}
      <Tour
        current={step}
        disabledInteraction={step !== 2 && step !== 3 && step !== 6}
        open={open}
        rootClassName="mobile-ant-tour"
        steps={isMobile ? mobileSteps : steps}
        gap={{
          offset,
        }}
        indicatorsRender={() => (
          <>
            {!isMobile && (
              <i
                style={{ color: 'var(--icon-color)', cursor: 'pointer' }}
                className={`${
                  audioEnabled ? 'icofont-volume-down' : 'icofont-volume-mute'
                } fs-5`}
                onClick={() => {
                  setAudioEnabled((prev) => {
                    return !prev
                  })
                  if (audioEnabled) {
                    tourAudio?.pause()
                  } else {
                    tourAudio?.play()
                  }
                }}
              ></i>
            )}
          </>
        )}
        onClose={handleClose}
        onFinish={handleClose}
        onChange={(e) => {
          setStep(e)

          if (isMobile) return

          switch (e) {
            case 2:
              break
            case 4:
              setOverlayTour(false)
              setFeedbackModal(false)
              setOffset([1, 1])
              break
            case 5:
              setOffset([50, 150])
              setOverlayTour(false)
              setFeedbackModal(true)

              break
            case 6:
              setOffset([1, 1])
              setFeedbackModal(false)
              break
          }
          if (e === 3) return
          playAudio(mp3Links[e])
        }}
      />
    </>
  )
}

export default FeedFwdTour
