import { Stack } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import error from '../../assets/images/error.png'

export default function Error() {
  const { t } = useTranslation()
  return (
    <Stack style={{ textAlign: 'center' }}>
      <img alt="error" src={error} width="200px" style={{ margin: 'auto' }} />
      <p style={{ fontWeight: 'bold' }}>{t('common.toast.error')}</p>
    </Stack>
  )
}
