const AWSS3FolderName = {
  USERS: 'users',
  ORGANIZATIONS: 'organizations',
  DOCUMENTS: 'documents',
  TASKS: 'tasks',
}

const uploadFileToCogarch = async (jwtToken, formData, organisationId) => {
  const response = await fetch(
    process.env.REACT_APP_CARCH_ENDPOINT +
      `/embeddings?organisation_id=${organisationId}`,
    {
      method: 'POST',
      headers: {
        // 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
      body: formData,
    },
  )
  const responseJson = await response.json()
  if (responseJson.status !== 'success') {
    throw new Error(responseJson.detail)
  }
  return responseJson.data
}

const updateFileInCogarch = async (jwtToken, formData, embedding_id) => {
  await fetch(
    process.env.REACT_APP_CARCH_ENDPOINT + `/embeddings/${embedding_id}`,
    {
      method: 'PUT',
      headers: {
        // 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwtToken}`,
      },
      timeout: 20000,
      body: formData,
    },
  )
}

const deleteFileFromCogarch = async (jwtToken, embedding_id) => {
  const response = await fetch(
    process.env.REACT_APP_CARCH_ENDPOINT + `/embeddings/${embedding_id}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    },
  )
  const responseJson = await response.json()
  if (responseJson.status !== 'success') {
    throw new Error('Failed to delete file from Cogarch')
  }
  return responseJson.status
}

const base64ToFile = (
  base64Data,
  filename,
  contentType = 'application/octet-stream',
) => {
  // Ensure base64Data has the correct prefix
  const prefixMatch = base64Data.match(/^data:(.+);base64,/)
  let base64Cleaned = base64Data

  if (prefixMatch) {
    contentType = prefixMatch[1]
    base64Cleaned = base64Data.substring(prefixMatch[0].length)
  }

  // Convert base64 to raw binary data
  const byteCharacters = atob(base64Cleaned)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)

  // Create a blob from the binary data
  const blob = new Blob([byteArray], { type: contentType })

  // Create and return a File object
  return new File([blob], filename, { type: contentType })
}

export {
  AWSS3FolderName,
  base64ToFile,
  deleteFileFromCogarch,
  updateFileInCogarch,
  uploadFileToCogarch,
}
