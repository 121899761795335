import { marked } from 'marked'
import { Fragment, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import getURIFileType from '../../../utils/getURIFileType'
import Tooltip from '../../UI/Tooltip'
import './FileReference.style.css'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs'

const commonStyle = {
  fontSize: '14px',
  marginRight: '8px',
  display: 'inline-flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'white',
  border: '1px solid #0056b3',
  borderRadius: '4px',
  padding: '4px 8px',
  transition: 'background-color 0.3s, color 0.3s',
  marginBottom: '4px',
}

const hoverStyle = {
  backgroundColor: '#0056b3',
  color: '#fff',
}

const FileReference = ({ id, text, link, tooltip, onClick }) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [mdFile, setMdFile] = useState()

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handleClose = () => setShow(false)

  const handleShow = () => setShow(true)

  useEffect(() => {
    if (link && getURIFileType(link) === 'md') {
      fetch(link)
        .then((response) => response.text())
        .then((text) => setMdFile(marked(text)))
        .catch((error) => console.log(error))
    }
  }, [])

  return (
    <Fragment>
      <Tooltip>
        {/* {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={commonStyle}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor
            e.currentTarget.style.color = hoverStyle.color
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = ''
            e.currentTarget.style.color = commonStyle.color
          }}
        >
          {text}
        </a>
      ) : ( */}
        <Button
          style={commonStyle}
          onClick={handleShow}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor
            e.currentTarget.style.color = hoverStyle.color
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = ''
            e.currentTarget.style.color = commonStyle.color
          }}
        >
          {text}
        </Button>
        {/* )} */}
      </Tooltip>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{text}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getURIFileType(link) === 'pdf' ? (
            <Document file={link} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          ) : getURIFileType(link) === 'md' ? (
            <article dangerouslySetInnerHTML={{ __html: mdFile }} />
          ) : (
            <a download href={link}>
              <Button variant="primary">
                {t('caiPopup.fileReference.downloadFile', 'Download File')}
              </Button>
            </a>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default FileReference
